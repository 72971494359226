import { fetch, post } from '@/core/legacy/api';
import { eventsUrl, bookingOptionsUrl } from '@/api/legacy/urls';

export function legacyLogin(origin, username, password) {
    return window.axios.post('/api/proxy/check-in/login', {
        origin,
        route: 'auth',
        username,
        password,
    });
}

export function fetchEvents() {
    return fetch('list-events');
}

export function fetchBookingOptions(url = eventsUrl()) {
    return fetch(`list-price-levels?${url}`);
}

export function fetchBookingOptionAttendees(url) {
    return fetch(`attendees-list?${url}`);
}

export function fetchAttendees(url) {
    return fetch(`attendees-list?${bookingOptionsUrl()}${url}`);
}

export function fetchAttendee(attendeeId) {
    return fetch(`attendee-card?guestId=${attendeeId}&${bookingOptionsUrl()}`);
}

export function checkInAttendee(attendeeId) {
    return post(`in?guestId=${attendeeId}&${bookingOptionsUrl()}`);
}

export function checkInTicket(attendeeId, bookingOption) {
    return post(`ticket-in?guestId=${attendeeId}&priceLevelId=${bookingOption}`);
}

export function checkOutTicket(attendeeId, bookingOption) {
    return post(`ticket-out?guestId=${attendeeId}&priceLevelId=${bookingOption}`);
}
