<template>
    <app-page
        title="Attendee Profile"
        :loading="isLoading"
        :error="hasError"
    >
        <div v-if="!hasError" class="space-y-4">
            <app-widget :title="attendee.fullName">
                <legacy-attendee-details :attendee="attendee" />
            </app-widget>

            <div class="mt-4 space-y-4">
                <app-widget v-for="event in events" :key="event.id" :title="event.name">
                    <legacy-attendee-event-details :event="event" />
                </app-widget>
            </div>
        </div>
    </app-page>
</template>

<script>
import { fetchAttendee } from '@/api/legacy';

import LegacyAttendeeDetails from './components/LegacyAttendeeDetails.vue';
import LegacyAttendeeEventDetails from './components/LegacyAttendeeEventDetails.vue';

export default {
    name: 'PageLegacyAttendeeProfile',

    components: {
        LegacyAttendeeDetails,
        LegacyAttendeeEventDetails,
    },

    props: {
        attendeeId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isLoading: true,
            isCheckingIn: false,
            hasError: false,
            attendee: '',
            events: [],
        };
    },

    created() {
        fetchAttendee(this.attendeeId)
            .then(response => {
                console.log('PageAttendeeProfile (attendee): The attendee was fetched.', response.data.data);
                this.attendee = response.data.data.guest;
                this.events = response.data.data.events;
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },
};
</script>
