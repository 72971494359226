import PageLogin from '@/pages/login/PageLogin.vue';

import { loginPageGuard, authGuard } from '@/routes/guards';

import legacy from './legacy';
import beyond from './beyond';

export default [
    {
        path: '*',
        redirect: '/',
        beforeEnter: authGuard,
    },
    {
        name: 'login',
        path: '/',
        components: { index: PageLogin },
        beforeEnter: loginPageGuard,
    },

    ...legacy,
    ...beyond,
];
