import events from './events';
import AppNotifications from './AppNotifications.vue';

export default {
    install(Vue, args = {}) {
        if (this.isInstalled) return;

        this.isInstalled = true;
        this.params = args;

        Vue.component(args.componentName || 'app-notifications', AppNotifications);

        const notification = params => {
            let paramsCopy = params;

            if (typeof paramsCopy === 'string') {
                paramsCopy = { title: '', message: paramsCopy };
            }

            if (typeof paramsCopy === 'object') {
                events.$emit('add', paramsCopy);
            }
        };

        notification.close = id => events.$emit('close', id);

        Vue.prototype.$notify = notification;
        Vue.notify = notification;
    },
};
