<template>
    <div class="flex justify-between items-center">
        <span class="font-semibold text-gray-700">
            {{ attendee.fullName }}
        </span>

        <div class="flex justify-center items-center -mr-2 w-12">
            <div
                v-if="isCheckedIn(attendee)"
                class="flex justify-center items-center w-6 h-6 bg-green-600 border-green-600 rounded-full"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
            </div>

            <div v-if="!isCheckedIn(attendee)" class="mt-0.5">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LegacyAttendeeItem',

    props: {
        attendee: {
            type: Object,
            required: true,
        },
    },

    methods: {
        isCheckedIn(attendee) {
            return attendee.ticketsData.tickedOffTicketsCount === attendee.ticketsData.ticketsCount;
        },
    },
};
</script>
