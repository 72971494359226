<template>
    <app-page title="QR Scanner" paddingless class="relative">
        <template #actions>
            <div class="flex items-center justify-end">
                <button
                    v-if="showCameraSwitcher && !hasOverconstrainedError"
                    type="button"
                    class="mr-3 p-2 rounded-full"
                    @click.prevent="switchCamera"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        data-name="Layer 1"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        stroke-width="0.2"
                    >
                        <path d="M12.29,5.21l1.5,1.5a1,1,0,0,0,1.42,0,1,1,0,0,0,.13-1.21H19a1,1,0,0,0,0-2H15.34a1,1,0,0,0-.13-1.21,1,1,0,0,0-1.42,0l-1.5,1.5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76A1,1,0,0,0,12.29,5.21ZM22.92,9.12a1,1,0,0,0-.21-.33l-1.5-1.5a1,1,0,0,0-1.42,0,1,1,0,0,0-.13,1.21H16a1,1,0,0,0,0,2h3.66a1,1,0,0,0,.13,1.21,1,1,0,0,0,1.42,0l1.5-1.5a1,1,0,0,0,.21-.33A1,1,0,0,0,22.92,9.12ZM11,10a4,4,0,1,0,4,4A4,4,0,0,0,11,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,11,16Zm9-3a1,1,0,0,0-1,1v5a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H6a1,1,0,0,0,1-.69l.54-1.62A1,1,0,0,1,8.44,7H10a1,1,0,0,0,0-2H8.44A3,3,0,0,0,5.59,7.06L5.28,8H4a3,3,0,0,0-3,3v8a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V14A1,1,0,0,0,20,13Z" fill="currentColor" />
                    </svg>
                </button>

                <button
                    type="button"
                    class="-mr-2 p-2 rounded-full"
                    @click.prevent="$router.push({ name: 'beyond-tickets-list', params: { eventId } })"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                </button>
            </div>
        </template>

        <qr-scanner
            v-if="isVisibleScanner"
            :camera-facing-mode="cameraFacingMode"
            :has-overconstrained-error.sync="hasOverconstrainedError"
            @decode="onDecode"
            @reset="resetCameraFacingMode"
        />

        <div v-if="isCheckedIn" class="absolute inset-0 bg-black bg-opacity-50" />

        <app-error v-if="error" :message="error" solid class="absolute z-10 top-24 left-6 right-6" />

        <div
            v-if="isCheckedIn"
            class="absolute z-10 top-40 left-12 right-12 flex items-center flex-col"
        >
            <div class="flex flex-shrink-0 justify-center items-center w-28 h-28 bg-green-600 border-green-600 rounded-full">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-16 w-16 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
            </div>

            <div class="mt-6 text-lg font-semibold text-white">
                Checked in!
            </div>

            <app-button
                v-if="isCheckedIn && attendeeId"
                appearance="primary"
                size="lg"
                class="mt-10 w-60"
                @click="$router.push({ name: 'beyond-attendee-profile', params: { eventId, attendeeId } })"
            >
                Visit attendee profile
            </app-button>

            <app-button
                v-if="isCheckedIn && attendeeId"
                appearance="outline"
                size="lg"
                class="mt-4 w-60 opacity-75"
                @click="reloadScanner"
            >
                New scan
            </app-button>
        </div>

        <div class="fixed bottom-0 left-0 right-0 p-6">
            <app-button
                appearance="primary"
                size="lg"
                class="w-full"
                @click="$router.push({ name: 'beyond-check-in-attendees', params: { eventId } })"
            >
                Manual check in
            </app-button>
        </div>
    </app-page>
</template>

<script>
import { mapGetters } from 'vuex';

import QrScanner from '@/components/wrappers/QrScanner.vue';

import { checkInAttendees } from '@/api/beyond';

export default {
    name: 'PageBeyondScanner',

    components: {
        QrScanner,
    },

    props: {
        eventId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isVisibleScanner: true,
            isCheckedIn: false,
            showCameraSwitcher: false,
            hasOverconstrainedError: false,
            cameraFacingMode: 'auto',
            attendeeId: '',
            error: '',
            result: '',
            errorMessage: {
                invalid: 'This is not a valid attendee QR code.<br><br> Press <strong>"Manual check in"</strong> to check in the attendee manually.',
                notFound: 'The attendee was not found.<br><br> Press <strong>"Manual check in"</strong> to check in the attendee manually.',
                failed: 'It was not possible to check in the attendee.<br><br> Press <strong>"Manual check in"</strong> to check in the attendee manually instead.',
            },
        };
    },

    created() {
        this.checkForMultipleCameras();
    },

    computed: {
        ...mapGetters('beyond', ['selectedTickets']),
    },

    methods: {
        async checkForMultipleCameras() {
            const devices = await navigator.mediaDevices?.enumerateDevices();

            if (devices) {
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                this.showCameraSwitcher = videoDevices.length > 1;
            }
        },

        reloadScanner() {
            this.isVisibleScanner = false;
            this.isCheckedIn = false;
            this.attendeeId = '';

            setTimeout(() => {
                this.isVisibleScanner = true;
            }, 50);
        },

        switchCamera() {
            const modes = {
                auto: 'front',
                rear: 'front',
                front: 'rear',
            };

            this.cameraFacingMode = modes[this.cameraFacingMode];
        },

        resetCameraFacingMode() {
            this.cameraFacingMode = 'auto';
        },

        onDecode(attendeeId) {
            const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-6][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

            if (!uuidRegex.test(attendeeId)) {
                this.error = this.errorMessage.invalid;
                return;
            }

            this.onCheckIn(attendeeId);
        },

        handleError(attendeeId, error) {
            const errorCode = error.status;

            switch (errorCode) {
                case 404:
                    this.error = this.errorMessage.notFound;
                    break;
                case 405:
                    this.$router.push({ name: 'beyond-check-in-companions', params: { eventId: this.eventId, attendeeId } });
                    break;
                case 406:
                    this.error = error.data.message;
                    break;
                case 422:
                    if (error.data.message !== 'group_booker') {
                        this.$router.push({ name: 'beyond-attendee-profile', params: { eventId: this.eventId, attendeeId } });
                    } else {
                        this.$router.push({ name: 'beyond-check-in-companions', params: { eventId: this.eventId, attendeeId } });
                    }
                    break;
                default:
                    this.error = this.errorMessage.failed;
                    break;
            }
        },

        onCheckIn(attendeeId) {
            const tickets = this.selectedTickets.map(item => ({ ticketable_id: item.id, ticketable_type: item.type }));
            checkInAttendees(this.eventId, attendeeId, { tickets })
                .then(() => {
                    this.isCheckedIn = true;
                    this.attendeeId = attendeeId;
                })
                .catch(error => {
                    this.handleError(attendeeId, error.response);
                });
        },
    },
};
</script>
