<template>
    <div
        v-if="isVisible"
        class="absolute bottom-0 left-0 right-0 flex items-center py-5 px-6 w-full bg-gray-100 border-t border-gray-200"
    >
        <div class="flex-1 text-xs font-semibold text-gray-700 leading-normal">
            To get the best experience, we recommend you to install the app.
        </div>

        <div class="flex items-center ml-6 space-x-4">
            <app-button appearance="primary" @click="onInstall">
                Install
            </app-button>

            <button
                type="button"
                class="text-xs text-gray-500 cursor-pointer focus:outline-none"
                @click.stop="onDismiss"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-px h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PwaInstallation',

    data() {
        return {
            isVisible: false,
            installEvent: '',
        };
    },

    created() {
        window.addEventListener('beforeinstallprompt', event => {
            event.preventDefault();
            this.installEvent = event;
            this.isVisible = true;
        });
    },

    methods: {
        onDismiss() {
            this.isVisible = false;
        },

        onInstall() {
            this.installEvent.prompt();

            this.installEvent.userChoice
                .then(() => {
                    this.onDismiss();
                });
        },
    },
};
</script>
