<template>
    <app-page
        title="Check in options"
        :loading="isLoading"
        :error="hasError"
    >
        <template v-if="!hasError">
            <app-widget :title="attendee.full_name">
                <beyond-attendee-details :attendee="attendee" />

                <div class="mt-5 pt-5 border-t border-gray-200">
                    <router-link
                        :to="{ name: 'beyond-attendee-profile', params: { eventId, attendeeId } }"
                        class="link"
                    >
                        To attendee profile
                    </router-link>
                </div>
            </app-widget>

            <div class="mt-4 space-y-2">
                <app-card v-for="ticket in tickets" :key="ticket.title" :class="{ 'mb-4': ticket.ticketable_type === 'App\\Project' }">
                    <div class="flex justify-between items-center">
                        <div>
                            <h2 class="text-lg font-semibold line-clamp-2">
                                {{ ticket.title }}
                            </h2>
                        </div>

                        <div class="ml-4 -mr-2.5">
                            <app-checkbox-toggle
                                :checked="ticket.checked_in"
                                @click.native.stop="onToggleCheckIn(ticket)"
                            />
                        </div>
                    </div>
                </app-card>
            </div>
        </template>
    </app-page>
</template>

<script>
import BeyondAttendeeDetails from './components/BeyondAttendeeDetails.vue';

import {
    fetchAttendee,
    checkInAttendee,
    checkOutAttendee,
} from '@/api/beyond';

export default {
    name: 'PageBeyondCheckInOptions',

    components: {
        BeyondAttendeeDetails,
    },

    props: {
        eventId: {
            type: String,
            required: true,
        },

        attendeeId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isLoading: true,
            hasError: false,
            tickets: [],
            attendee: '',
        };
    },

    created() {
        fetchAttendee(this.eventId, this.attendeeId)
            .then(response => {
                console.log('PageCheckInOptions (attendee): The attendee was fetched.', response.data.data);
                this.attendee = response.data.data.guest;
                this.tickets = response.data.data.events;
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    methods: {
        updateOptionTicketData(ticket, status) {
            ticket.checked_in = status;
        },

        onToggleCheckIn(ticket) {
            !ticket.checked_in
                ? this.onCheckIn(ticket)
                : this.onCheckOut(ticket);
        },

        onCheckIn(ticket) {
            checkInAttendee(this.eventId, this.attendeeId, { ticket })
                .then(() => {
                    this.$notify(`Checked in to <strong>${ticket.title}</strong> successfully.`);
                    this.updateOptionTicketData(ticket, true);
                })
                .catch(() => {
                    this.$notify({
                        status: 'error',
                        message: 'An unexpected error occured while trying to check in the attendee.<br> Please try again later.',
                    });
                });
        },

        onCheckOut(ticket) {
            checkOutAttendee(this.eventId, this.attendeeId, { ticket })
                .then(() => {
                    this.$notify(`Checked out from <strong>${ticket.title}</strong> successfully.`);
                    this.updateOptionTicketData(ticket, false);
                })
                .catch(() => {
                    this.$notify({
                        status: 'error',
                        message: 'IAn unexpected error occured while trying to check out the attendee.<br> Please try again later.',
                    });
                });
        },
    },
};
</script>
