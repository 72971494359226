<template>
    <div class="mt-5 space-y-6">
        <div
            v-for="option in event.priceLevels"
            :key="option.id"
            class="flex justify-between items-start pb-5 last:pb-0 text-gray-700 border-b last:border-0 border-gray-200"
        >
            <div class="w-full font-semibold">
                <div class="flex justify-between" :class="{ 'pb-4 border-b border-gray-200': option.questions }">
                    <span class="mr-5 line-clamp-2">
                        {{ option.name }}
                    </span>

                    <div class="flex-shrink-0 flex justify-center items-center">
                        <div
                            v-if="isCheckedIn(option)"
                            class="flex justify-center items-center w-6 h-6 bg-green-600 border-green-600 rounded-full"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4 text-white"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                        </div>

                        <div v-if="!isCheckedIn(option)" class="mt-0.5 mr-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4 text-gray-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div v-if="option.questions" class="mt-5 text-sm">
                    <div v-for="(group, groupIndex) in option.questions" :key="groupIndex">
                        <div class="space-y-5">
                            <div v-for="(question, questionIndex) in group" :key="questionIndex">
                                <div class="mb-2 font-semibold">
                                    {{ question.question }}
                                </div>

                                <div class="space-y-1 font-normal">
                                    <template v-if="question.answers.length">
                                        <div v-for="(answer, answerIndex) in question.answers" :key="answerIndex">
                                            {{ answer }}
                                        </div>
                                    </template>
                                    <div v-else>
                                        -
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LegacyAttendeeEventDetails',

    props: {
        event: {
            type: Object,
            required: true,
        },
    },

    methods: {
        isCheckedIn(option) {
            return option.ticketsData.tickedOffTicketsCount === option.ticketsData.ticketsCount;
        },
    },
};
</script>
