<template>
    <div>
        <div
            v-for="item in items"
            :key="item.id"
            :class="itemClass"
        >
            <slot name="item" :item="item" />
        </div>

        <div
            v-if="items.length"
            v-observe-visibility="scrolledToBottom"
            style="margin: 0;"
        />
    </div>
</template>

<script>
export default {
    name: 'InfiniteScroll',

    props: {
        items: {
            required: true,
            type: Array,
        },

        page: {
            required: false,
            type: Number,
            default: 1,
        },

        lastPage: {
            required: true,
            type: Number,
        },

        itemClass: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            currentPage: this.page,
        };
    },

    methods: {
        scrolledToBottom(isVisible) {
            if (!isVisible) return;
            if (this.currentPage > this.lastPage) return;

            this.currentPage += 1;

            this.$emit('refetch', this.currentPage);
        },
    },
};
</script>
