<template>
    <div class="mt-2 ml-px text-xs font-bold text-red-700" v-html="error" />
</template>

<script>
export default {
    name: 'FormUtilError',

    props: {
        error: {
            type: String,
            required: true,
        },
    },
};
</script>
