<template>
    <switch-toggle
        :is-loading="isCheckingIn"
        :checked="checkedIn"
        :disabled="disabled"
        @click="onToggleCheckIn"
    />
</template>

<script>
import SwitchToggle from '@/components/utils/SwitchToggle.vue';

import { manageCompanionCheckIns } from '@/api/beyond';

export default {
    name: 'BeyondCompanionsToggle',

    components: {
        SwitchToggle,
    },

    props: {
        participants: {
            type: Array,
            required: true,
        },

        tickets: {
            type: Array,
            required: true,
        },

        checkedIn: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isCheckingIn: false,
        };
    },

    methods: {
        onToggleCheckIn() {
            this.isCheckingIn = true;

            const participantIds = this.participants.map(item => item.id);
            const tickets = this.tickets.map(item => ({ ticketable_id: item.ticketable_id, ticketable_type: item.ticketable_type }));
            const checkedIn = !this.checkedIn;

            manageCompanionCheckIns(this.$route.params.eventId, participantIds, tickets, checkedIn)
                .then(() => {
                    const message = !checkedIn
                        ? 'Checked out all the participants in the group booking from the tickets.'
                        : 'Checked in all the participants in the group booking to the tickets.';

                    this.$notify(message);
                    this.$emit('update', checkedIn);
                })
                .catch(error => {
                    this.$emit('error', error.response.data);
                })
                .finally(() => {
                    this.isCheckingIn = false;
                });
        },
    },
};
</script>
