<template>
    <button
        v-bind="$attrs"
        :type="type"
        class="button"
        :class="customColor ? customClasses : classes"
        :disabled="disabled"
        @click="$emit('click')"
    >
        <slot />
    </button>
</template>

<script>
export default {
    name: 'AppButton',

    props: {
        type: {
            type: String,
            default: 'button',
        },

        appearance: {
            type: String,
            default: 'default',
        },

        size: {
            type: String,
            default: 'default',
        },

        customColor: {
            type: String,
            required: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                'button-default': this.appearance === 'default',
                'button-primary': this.appearance === 'primary',
                'button-light': this.appearance === 'light',
                'button-danger': this.appearance === 'danger',
                'button-warning': this.appearance === 'warning',
                'button-outline-primary': this.appearance === 'outline',
                'button-custom': this.appearance === 'custom',
                'is-loading': this.loading,
                'is-small': this.size === 'sm',
                'is-large': this.size === 'lg',
                'opacity-50 cursor-not-allowed': this.disabled,
            };
        },

        customClasses() {
            return `bg-${this.customColor} text-white border-${this.customColor} hover:bg-opacity-90`;
        },
    },
};
</script>
