var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{ref:"label",class:[
        [_vm.group ? 'flex' : 'inline-flex'],
        [_vm.group && !_vm.error ? 'items-center' : 'items-start'],
        { 'items-start': _vm.group && _vm.error },
        { 'flex-col': _vm.error },
        { 'cursor-not-allowed': _vm.disabled },
    ],attrs:{"for":_vm.id},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();return _vm.$refs.label.click.apply(null, arguments)}}},[_c('div',{staticClass:"flex items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newValue),expression:"newValue"}],staticClass:"form-radio",class:[_vm.group ? 'top-0.5' : '-top-px'],attrs:{"name":_vm.name,"required":_vm.required,"disabled":_vm.disabled,"tabindex":_vm.disabled ? false : 0,"type":"radio"},domProps:{"value":_vm.nativeValue,"checked":_vm._q(_vm.newValue,_vm.nativeValue)},on:{"change":[function($event){_vm.newValue=_vm.nativeValue},function($event){return _vm.$emit('change')}]}}),_c('div',{staticClass:"text-sm font-semibold text-gray-700",class:[!_vm.group ? 'ml-2.5' : 'ml-4']},[_vm._t("default"),(_vm.error)?_c('form-util-error',{attrs:{"error":_vm.error}}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }