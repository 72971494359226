import Vue from 'vue';

import AppPage from '@/components/global/AppPage.vue';
import AppLoader from '@/components/global/AppLoader.vue';
import AppNoItems from '@/components/global/AppNoItems.vue';
import AppError from '@/components/global/AppError.vue';
import AppCard from '@/components/global/AppCard.vue';
import AppWidget from '@/components/global/AppWidget.vue';
import AppButton from '@/components/global/AppButton.vue';
import AppBadge from '@/components/global/AppBadge.vue';
import AppMessage from '@/components/global/AppMessage.vue';
import AppCheckboxToggle from '@/components/global/AppCheckboxToggle.vue';

Vue.component('AppPage', AppPage);
Vue.component('AppLoader', AppLoader);
Vue.component('AppNoItems', AppNoItems);
Vue.component('AppError', AppError);
Vue.component('AppCard', AppCard);
Vue.component('AppWidget', AppWidget);
Vue.component('AppButton', AppButton);
Vue.component('AppBadge', AppBadge);
Vue.component('AppMessage', AppMessage);
Vue.component('AppCheckboxToggle', AppCheckboxToggle);
