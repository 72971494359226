<template>
    <label :for="id" class="block">
        <form-util-label v-if="label" :size="size" :required="required">
            {{ label }}
        </form-util-label>

        <div class="relative">
            <span
                v-if="search"
                name="search"
                tabindex="-1"
                class="absolute top-3.5 left-5 text-xs text-gray-400 pointer-events-none"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mt-px h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </span>

            <input
                :id="id"
                ref="input"
                v-model="newValue"
                :value="nativeValue"
                :type="type"
                :name="name"
                v-bind="$attrs"
                :placeholder="placeholder"
                :maxlength="maxLength"
                class="form-input w-full rounded-lg"
                :class="[themeClasses, inputClasses, { 'bg-red-100 border border-red-700': error, 'pl-14': search }]"
                :disabled="disabled"
                @blur="$emit('blur', $event.target.value)"
            >

            <button
                v-if="search && newValue"
                type="button"
                class="absolute top-4 right-5 text-xs text-gray-500 cursor-pointer focus:outline-none"
                @click.stop="$emit('clear')"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-px h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>

        <form-util-error v-if="error" :error="error" />
    </label>
</template>

<script>
import FormUtilLabel from './utils/FormUtilLabel.vue';
import FormUtilError from './utils/FormUtilError.vue';

export default {
    name: 'FormInput',

    components: {
        FormUtilLabel,
        FormUtilError,
    },

    props: {
        value: {
            type: [String, Number, Boolean, Function, Object, Array],
            default: '',
        },

        nativeValue: {
            type: [String, Number, Boolean, Function, Object, Array],
        },

        type: {
            type: String,
            required: true,
        },

        id: {
            type: String,
        },

        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
        },

        placeholder: {
            type: String,
        },

        size: {
            type: String,
            default: 'default',
        },

        search: {
            type: Boolean,
            default: false,
        },

        theme: {
            type: String,
            default: 'default',
        },

        required: {
            type: Boolean,
        },

        maxLength: {
            type: Number,
            default: 180,
        },

        disabled: {
            type: Boolean,
        },

        error: {
            type: String,
        },
    },

    data() {
        return {
            newValue: this.value,
            showPassword: false,
        };
    },

    created() {
        if (this.nativeValue === '') {
            this.newValue = '';
        }

        if (this.nativeValue) {
            this.newValue = this.nativeValue;
        }
    },

    watch: {
        value(value) {
            this.newValue = value;
        },

        newValue(value) {
            this.$emit('input', value);
        },
    },

    computed: {
        inputClasses() {
            const classes = {
                default: 'py-1.5 px-3 text-tiny',
                lg: 'py-3.5 px-4 text-base',
            };

            return classes[this.size];
        },

        themeClasses() {
            const classes = {
                default: 'bg-gray-100 border border-gray-200',
                light: 'bg-gray-50 border border-gray-300',
            };

            return classes[this.theme];
        },
    },
};
</script>
