<template>
    <div class="pb-5 last:pb-0 space-y-5">
        <div class="pb-5 text-base border-b border-gray-200">
            <span class="block md:inline-block mb-2 md:mb-0 md:mr-8 w-80 font-semibold text-gray-700">
                Title
            </span>
            <span>
                {{ resource.group }}
            </span>
        </div>

        <div class="pb-5 text-base border-b border-gray-200">
            <span class="block md:inline-block mb-2 md:mb-0 md:mr-8 w-80 font-semibold text-gray-700">
                Resource
            </span>
            <span>
                {{ resource.resource }}
            </span>
        </div>

        <div class="text-base border-b-0">
            <span class="block md:inline-block mb-2 md:mb-0 md:mr-8 w-80 font-semibold text-gray-700">
                Position
            </span>
            <span>
                {{ resource.placement }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BeyondAttendeeResource',

    props: {
        resource: {
            type: Object,
            required: true,
        },
    },
};
</script>
