<template>
    <app-page
        title="Check in options"
        :loading="isLoading"
        :error="hasError"
    >
        <template v-if="!hasError">
            <app-widget :title="attendee.fullName">
                <legacy-attendee-details :attendee="attendee" />

                <div class="mt-5 pt-5 border-t border-gray-200">
                    <router-link
                        :to="{ name: 'legacy-attendee', params: { attendeeId } }"
                        class="link"
                    >
                        To attendee profile
                    </router-link>
                </div>
            </app-widget>

            <div class="mt-4 space-y-2">
                <app-card v-for="option in options" :key="option.id">
                    <div class="flex justify-between items-center">
                        <div>
                            <h2 class="text-lg font-semibold line-clamp-2">
                                {{ option.name }}
                            </h2>
                        </div>

                        <div class="ml-4 -mr-2.5">
                            <app-checkbox-toggle
                                :checked="isCheckedIn(option)"
                                @click.native.stop="onToggleCheckIn(option)"
                            />
                        </div>
                    </div>
                </app-card>
            </div>
        </template>
    </app-page>
</template>

<script>
import {
    fetchAttendee,
    checkInTicket,
    checkOutTicket,
} from '@/api/legacy';

import LegacyAttendeeDetails from './components/LegacyAttendeeDetails.vue';

export default {
    name: 'PageLegacyCheckInBookingOptions',

    components: {
        LegacyAttendeeDetails,
    },

    props: {
        attendeeId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isLoading: true,
            hasError: false,
            options: [],
            attendee: '',
        };
    },

    created() {
        fetchAttendee(this.attendeeId)
            .then(response => {
                console.log('PageCheckInBookingOptions (attendee): The attendee was fetched.', response.data.data);
                this.attendee = response.data.data.guest;
                this.options = response.data.data.events[0].priceLevels;
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    methods: {
        isCheckedIn(option) {
            return option.ticketsData?.tickedOffTicketsCount === option.ticketsData?.ticketsCount;
        },

        updateOptionTicketData(option, count) {
            option.ticketsData.tickedOffTicketsCount = count;
        },

        onToggleCheckIn(option) {
            !this.isCheckedIn(option) ? this.onCheckIn(option) : this.onCheckOut(option);
        },

        onCheckIn(option) {
            checkInTicket(this.attendeeId, option.id)
                .then(() => {
                    this.updateOptionTicketData(option, 1);
                })
                .catch(() => {
                    this.$notify({ message: 'It wasn\'t possible to check in the attendee.<br> Try again later.', status: 'error' });
                });
        },

        onCheckOut(option) {
            checkOutTicket(this.attendeeId, option.id)
                .then(() => {
                    this.updateOptionTicketData(option, 0);
                })
                .catch(() => {
                    this.$notify({ message: 'It wasn\'t possible to check out the attendee.<br> Try again later.', status: 'error' });
                });
        },
    },
};
</script>
