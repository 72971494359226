<template>
    <div class="text-sm font-semibold leading-5 rounded-lg" :class="[sizes, classes]">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'AppMessage',

    props: {
        type: {
            type: String,
            default: 'default',
        },

        size: {
            type: String,
            default: 'default',
        },
    },

    computed: {
        sizes() {
            const sizes = {
                sm: 'py-3 px-4',
                default: 'py-4 px-6',
            };

            return sizes[this.size];
        },

        classes() {
            const types = {
                default: 'bg-gray-100 text-gray-700 border border-gray-300',
                primary: 'bg-blue-100 text-blue-800 border border-blue-300',
                success: 'bg-green-200/50 text-green-800 border border-green-600',
                warning: 'bg-yellow-100/60 text-yellow-800 border border-yellow-300',
                danger: 'bg-red-100 text-red-800 border border-red-300',
                error: 'bg-red-100 bg-opacity-50 text-red-800 border border-red-200',
            };

            return types[this.type];
        },
    },
};
</script>
