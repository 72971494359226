import Vue from 'vue';
import Vuex from 'vuex';

import { isLoggedIn, clearAccessToken } from '@/core/auth';
import { beyondLogout } from '@/api/beyond';
import router from '@/routes';

Vue.use(Vuex);

export default {
    namespaced: true,

    state: {
        token: null,
        expires_at: null,
    },

    getters: {
        isAuthenticated(state) {
            const isExpired = new Date(state.expires_at) < new Date();
            return state.token && !isExpired;
        },
    },

    mutations: {
        ADD_AUTH(state, auth) {
            state.token = auth.token;
            state.expires_at = auth.expires_at;
        },

        REMOVE_AUTH(state) {
            state.token = null;
            state.expires_at = null;
        },
    },

    actions: {
        addAuth({ commit }, auth) {
            commit('ADD_AUTH', {
                token: auth.token || auth.access_token,
                expires_at: auth.expires_in
                    ? new Date().getTime() + auth.expires_in * 1000
                    : new Date().getTime() + 604800 * 1000, // 1 week
            });

            if (auth.access_token) {
                window.axios.defaults.headers.common.Authorization = `Bearer ${auth.access_token}`;
            }
        },

        clearAll({ commit }) {
            commit('REMOVE_AUTH');
            commit('RESET_STATE', null, { root: true });
            commit('user/RESET_STATE', null, { root: true });
            commit('legacy/RESET_STATE', null, { root: true });
            commit('beyond/RESET_STATE', null, { root: true });

            clearAccessToken();

            if (router.history.current.name === 'login') return;

            router.replace({ name: 'login' });
        },

        logout({ rootState, dispatch }) {
            if (!isLoggedIn() || rootState.origin !== 'beyond') {
                dispatch('clearAll');
                return;
            }

            beyondLogout()
                .then(() => {
                    dispatch('clearAll');
                })
                .catch(error => {
                    if (error.response.status === 500) {
                        dispatch('clearAll');
                    }

                    console.log(error.response);
                });
        },
    },
};
