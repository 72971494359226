<template>
    <button
        type="button"
        :disabled="disabled || isLoading"
        class="switch-toggle"
        :class="{ checked, 'is-toggling': isLoading }"
        @click="$emit('click')"
    />
</template>

<script>
export default {
    name: 'SwitchToggle',

    props: {
        checked: {
            type: Boolean,
            default: false,
        },

        isLoading: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.switch-toggle {
    @apply relative inline-flex items-center p-1 w-9 h-5 bg-gray-400 rounded-full transition duration-100 ease-in-out;
}

.switch-toggle:before {
    @apply w-3.5 h-3.5 bg-gray-100 rounded-full;
    content: "";
    box-shadow: 0 3px 1px 0 rgba(black, 0.05), 0 2px 2px 0 rgba(black, 0.1), 0 3px 3px 0 rgba(black, 0.05);
    transition: transform 100ms ease-out, width 100ms ease-out;
    will-change: transform;
}

.checked.switch-toggle:before {
    transform: translate3d(100%, 0, 0);
}

.switch-toggle.checked {
    @apply bg-green-700 border-green-700;
}

.switch-toggle:disabled {
    @apply opacity-50 cursor-not-allowed;
}

.switch-toggle.is-toggling {
    @apply bg-transparent;
}

.switch-toggle.is-toggling:after {
    @apply inline-block absolute inset-0;
    content: url('data:image/svg+xml;charset=UTF-8, <svg aria-hidden="true" data-prefix="fas" data-icon="spinner" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23424242" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path></svg>');
    top: calc(50% - 6px);
    left: calc(50% - 6px);
    width: 12px;
    height: 24px;
    margin-top: -6px;
    animation: spin 2s infinite linear;
}
</style>
