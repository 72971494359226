<template>
    <app-page
        title="Attendees list"
        actionless
        paddingless
        :loading="isLoading"
        :error="hasError"
        class="relative"
    >
        <app-no-items
            v-if="!hasTickets && !hasError"
            message="There are no ticket options available in the selected events."
        />
        <template v-if="!hasError">
            <div class="sticky top-0 z-10 mx-2 pt-6 pb-4 px-4 bg-gray-200">
                <form-input
                    ref="search"
                    v-model="search"
                    size="lg"
                    name="search"
                    type="text"
                    theme="light"
                    search
                    placeholder="Search tickets..."
                    @clear="search = ''"
                />
            </div>

            <div class="p-6 pt-0">
                <app-card v-if="!hasFilteredTickets && search" class="text-sm font-semibold text-gray-600">
                    No ticket options matching "<strong>{{ search }}</strong>" found.
                </app-card>

                <div v-if="!hasFilteredTickets && !search" class="text-sm text-gray-600">
                    There are no ticket options available in this event.
                </div>

                <div v-if="hasFilteredTickets" class="mt-0.5 space-y-4">
                    <div v-for="ticket in filteredTickets" :key="ticket.id">
                        <div class="grid grid-cols-1 gap-2">
                            <router-link
                                :to="{ name: 'beyond-attendees-list', params: { eventId, ticketId: ticket.id, ticketType: ticket.type } }"
                                class="block"
                            >
                                <app-card>
                                    <div class="flex justify-between items-center">
                                        <div class="flex py-1">
                                            <div class="flex flex-col ml-2.5 h-full">
                                                <div class="flex-1">
                                                    <div class="text-lg font-semibold line-clamp-2">
                                                        {{ ticket.title }}
                                                    </div>
                                                    <div class="mt-1 text-sm font-semibold text-gray-500 line-clamp-1">
                                                        {{ ticket.checked_in_tickets_count }} / {{ ticket.purchased_ticket_count }} checked in
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="ml-4">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="h-7 w-7 text-gray-600"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                stroke-width="2"
                                            >
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>
                                    </div>
                                </app-card>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-page>
</template>

<script>
import { FormInput } from '@/components/form';

import { fetchTicketsList } from '@/api/beyond';

export default {
    name: 'PageBeyondTicketsList',

    components: {
        FormInput,
    },

    props: {
        eventId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isLoading: true,
            hasError: false,
            search: '',
            tickets: [],
        };
    },

    created() {
        fetchTicketsList(this.$route.params.eventId)
            .then(response => {
                console.log('PageTicketsList (tickets): The tickets were fetched.', response.data.data);
                this.tickets = response.data.data;
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    computed: {
        hasTickets() {
            return !!this.tickets.length;
        },

        hasFilteredTickets() {
            return !!this.filteredTickets.length;
        },

        filteredTickets() {
            if (!this.search) return this.tickets;

            const regex = new RegExp(`(${this.search})`, 'i');

            return [...this.tickets].filter(ticket => ticket.title.match(regex));
        },
    },

    methods: {
        getTicketType(type) {
            const types = {
                'App\\Project': 'Event',
                'App\\EntranceTicketType': 'Entrance ticket',
                'App\\ActivityTicket': 'Activity',
            };

            return types[type];
        },
    },
};
</script>
