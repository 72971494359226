<template>
    <app-page
        title="Attendees list"
        actionless
        paddingless
        :loading="isLoading"
        :error="hasError"
        class="relative"
    >
        <template v-if="!hasError">
            <div class="sticky top-0 z-10 mx-2 pt-6 pb-4 px-4 bg-gray-200">
                <form-input
                    ref="search"
                    v-model="search"
                    size="lg"
                    name="search"
                    type="text"
                    theme="light"
                    search
                    placeholder="Search attendees..."
                    @clear="clearSearch"
                />
            </div>

            <div v-if="!isLoading" class="p-6 pt-0 space-y-4">
                <app-widget :title="ticketDetails.event_name">
                    <h3 class="mt-4 text-base font-semibold line-clamp-2">
                        {{ ticketDetails.title }}
                    </h3>

                    <div class="mt-1 text-sm font-semibold text-gray-500">
                        {{ ticketDetails.checked_in_tickets_count }} / {{ ticketDetails.purchased_ticket_count }} tickets used
                    </div>
                </app-widget>

                <app-card v-if="!hasAttendees" class="text-sm font-semibold text-gray-600">
                    No registered attendees were found.
                </app-card>

                <app-card v-if="!hasVisibleAttendees && search" class="text-sm font-semibold text-gray-600">
                    No attendees matching "<strong>{{ search }}</strong>" found.
                </app-card>

                <app-card v-if="hasAttendees && hasVisibleAttendees">
                    <infinite-scroll
                        :items="attendees"
                        :page="pagination.page"
                        :last-page="pagination.lastPage"
                        class="space-y-4"
                        item-class="pt-4 first:pt-0 border-t first:border-t-0 border-gray-200"
                        @refetch="fetchMoreAttendees"
                    >
                        <template #item="{ item: attendee }">
                            <beyond-attendee-item :attendee="attendee" />
                        </template>
                    </infinite-scroll>
                </app-card>

                <app-loader v-if="isRefetching" class="py-3" />
            </div>
        </template>
    </app-page>
</template>

<script>
import debounce from 'lodash/debounce';

import { FormInput } from '@/components/form';
import InfiniteScroll from '@/components/utils/InfiniteScroll.vue';

import { fetchAttendeeList, fetchTicketDetails } from '@/api/beyond';

import BeyondAttendeeItem from './components/BeyondAttendeeItem.vue';

export default {
    name: 'PageBeyondAttendeesList',

    components: {
        FormInput,
        InfiniteScroll,
        BeyondAttendeeItem,
    },

    props: {
        eventId: {
            type: String,
            required: true,
        },

        ticketId: {
            type: String,
            required: true,
        },

        ticketType: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            hasError: false,
            isLoading: true,
            isFetching: false,
            isRefetching: false,
            isSearch: false,
            attendees: [],
            ticketDetails: '',
            search: '',
            pagination: {
                page: 1,
                lastPage: 1,
                total: 0,
            },
        };
    },

    created() {
        Promise.all([
            fetchTicketDetails(this.eventId, this.ticket),
            fetchAttendeeList(this.eventId, this.getUrlParams),
        ])
            .then(([ticket, attendees]) => {
                console.log('PageAttendeesList (ticket-details): The ticket details was fetched.', ticket.data);
                console.log('PageAttendeesList (attendees): The attendees list was fetched.', attendees.data.data);

                this.ticketDetails = ticket.data;
                this.attendees = attendees.data.data;
                this.setPaginationData(attendees.data.meta);
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    watch: {
        search: debounce(function () {
            this.onSearch();
        }, 300),
    },

    computed: {
        hasAttendees() {
            return !!this.pagination.total;
        },

        hasVisibleAttendees() {
            return !!this.attendees.length;
        },

        ticket() {
            return { ticketable_id: this.ticketId, ticketable_type: this.ticketType };
        },

        getUrlParams() {
            return {
                page: this.pagination.page, search: this.search, ticket: this.ticket,
            };
        },
    },

    methods: {
        fetchAttendees() {
            this.isFetching = true;

            fetchAttendeeList(this.eventId, this.getUrlParams)
                .then(response => {
                    console.log('PageAttendeesList (attendees): The attendees were fetched.', response.data.data);
                    this.attendees = response.data.data;
                    this.setPaginationData(response.data.meta);
                })
                .catch(error => {
                    if (error.response.status >= 500) {
                        this.hasError = true;
                    }
                })
                .finally(() => {
                    this.isFetching = false;
                });
        },

        fetchMoreAttendees(page) {
            this.pagination.page = page;
            if (this.pagination.page > this.pagination.lastPage) return;

            this.isRefetching = true;

            fetchAttendeeList(this.eventId, this.getUrlParams)
                .then(response => {
                    console.log('PageAttendeesList (attendees): More attendees were fetched.', response.data.data);
                    this.attendees.push(...response.data.data);
                    this.pagination.lastPage = response.data.meta.last_page;
                })
                .catch(error => {
                    if (error.response.status >= 500) {
                        this.hasError = true;
                    }
                })
                .finally(() => {
                    this.isRefetching = false;
                });
        },

        onSearch() {
            this.isSearch = true;
            this.pagination.page = 1;

            this.fetchAttendees();
        },

        clearSearch() {
            this.search = '';
            this.isSearch = false;
            this.pagination.page = 1;

            this.fetchAttendees();
        },

        setPaginationData(data) {
            this.pagination.lastPage = data.last_page;

            if (this.isSearch) return;

            this.pagination.total = data.total;
        },
    },
};
</script>
