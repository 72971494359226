<template>
    <switch-toggle
        :is-loading="isCheckingIn"
        :checked="isCheckedIn"
        :disabled="disabled"
        @click="onToggleCheckIn"
    />
</template>

<script>
import SwitchToggle from '@/components/utils/SwitchToggle.vue';

import { manageCompanionCheckIns } from '@/api/beyond';

export default {
    name: 'CompanionCheckInToggle',

    components: {
        SwitchToggle,
    },

    props: {
        companion: {
            type: Object,
            required: true,
        },

        ticket: {
            type: Object,
            required: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isCheckingIn: false,
            isCheckedIn: this.ticket.checked_in,
        };
    },

    watch: {
        'ticket.checked_in'(checkedIn) {
            this.isCheckedIn = checkedIn;
        },
    },

    methods: {
        onToggleCheckIn() {
            this.isCheckingIn = true;

            const participantIds = [this.companion.id];
            const tickets = [{ ticketable_id: this.ticket.ticketable_id, ticketable_type: this.ticket.ticketable_type }];
            const checkedIn = !this.ticket.checked_in;

            manageCompanionCheckIns(this.$route.params.eventId, participantIds, tickets, checkedIn)
                .then(() => {
                    const message = !checkedIn
                        ? `Checked out <strong>${this.companion.first_name} ${this.companion.last_name}</strong> from <strong>${this.ticket.title}</strong>.`
                        : `Checked in <strong>${this.companion.first_name} ${this.companion.last_name}</strong> to <strong>${this.ticket.title}</strong>.`;

                    this.$notify(message);
                    this.$emit('update', checkedIn);
                })
                .catch(error => {
                    this.$emit('error', error.response.data);
                })
                .finally(() => {
                    this.isCheckingIn = false;
                });
        },
    },
};
</script>
