<template>
    <div class="flex bg-white rounded-lg shadow-sm overflow-hidden">
        <div v-if="image" class="relative w-48">
            <div v-if="date" class="absolute inset-0 flex justify-center items-end py-4 bg-gradient-to-t from-black/40">
                <div style="margin-bottom: 3px;" class="flex items-end text-sm font-bold text-white opacity-100">
                    {{ date }}
                </div>
            </div>
            <img :src="image" alt="Event image" class="object-center object-cover">
        </div>

        <div v-if="$slots.default" class="py-5 px-6 w-full">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppCard',

    props: {
        image: {
            type: String,
            required: false,
        },

        date: {
            type: String,
            required: false,
        },
    },
};
</script>
