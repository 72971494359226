<template>
    <div class="mt-5 space-y-6">
        <div
            v-for="event in events"
            :key="event.ticketable_id"
            class="flex justify-between items-start pb-5 last:pb-0 text-gray-600 border-b last:border-0 border-gray-200"
        >
            <div class="w-full font-semibold">
                <div class="flex justify-between">
                    <span class="mr-5 line-clamp-2">
                        {{ event.title }}
                    </span>

                    <div class="flex-shrink-0 flex justify-center items-center">
                        <div
                            v-if="event.checked_in"
                            class="flex justify-center items-center w-6 h-6 bg-green-600 border-green-600 rounded-full"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4 text-white"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </div>

                        <div v-if="!event.checked_in" class="mt-0.5 mr-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4 text-red-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="3"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div v-if="event.checked_in_at" class="text-sm mt-2 text-brand-500">
                    <span class="mr-1">Checked in at:</span>
                    <span>{{ getFormattedDate(event.checked_in_at) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns';

export default {
    name: 'BeyondAttendeeEventDetails',

    props: {
        events: {
            type: Array,
            required: true,
        },
    },

    methods: {
        getFormattedDate(date) {
            return format(new Date(date), 'yyyy-MM-dd HH:mm');
        },
    },
};
</script>
