<template>
    <div class="flex justify-center">
        <div class="lds-ellipsis" :class="[theme === 'brand' ? 'brand' : 'white']">
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppLoader',

    props: {
        theme: {
            type: String,
            default: 'brand',
            validator(value) {
                return ['brand', 'white'].includes(value);
            },
        },
    },
};
</script>

<style scoped>
.lds-ellipsis {
    @apply relative;
    width: 76px;
}

.lds-ellipsis div {
    @apply absolute top-0 w-3 h-3 rounded-full;
    will-change: transform;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis.brand div {
    @apply bg-brand-500;
}

.lds-ellipsis.white div {
    @apply bg-white bg-opacity-80;
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
</style>
