<template>
    <div class="w-full">
        <div class="flex justify-between items-center">
            <div class="flex">
                <h2 class="text-lg text-gray-800 font-semibold line-clamp-2">
                    {{ participant.first_name }} {{ participant.last_name }}
                </h2>

                <app-badge
                    v-if="participant.is_group_booker"
                    type="primary"
                    size="sm"
                    class="ml-4"
                >
                    Group booker
                </app-badge>
            </div>

            <companion-bulk-check-in-toggle
                v-if="participantTickets.length"
                :companion="participant"
                :tickets="participantTickets"
                @update="onToggleCheckIns"
                @error="onError"
            />
        </div>

        <div v-if="participant.email" class="text-gray-600 text-sm font-semibold">
            {{ participant.email }}
        </div>

        <div v-if="participantTickets.length" class="pt-3 mt-3 border-t border-gray-200 space-y-5">
            <div v-for="ticket in participantTickets" :key="ticket.id">
                <div class="flex justify-between items-center">
                    <div class="text-sm font-semibold text-gray-700">
                        {{ ticket.title }}
                    </div>

                    <companion-check-in-toggle
                        :companion="participant"
                        :ticket="ticket"
                        @update="onToggleCheckIn($event, ticket)"
                        @error="onError"
                    />
                </div>

                <div v-if="ticket.checked_in_at" class="text-xs mt-1 font-semibold text-brand-500">
                    <span class="mr-1">Checked in at:</span>
                    <span>{{ getFormattedDate(ticket.checked_in_at) }}</span>
                </div>
            </div>
        </div>

        <app-message v-else size="sm" class="mt-3">
            The participant does not have any of the selected tickets.
        </app-message>

        <form-util-error v-if="getError(participant.id)" :error="getError(participant.id)" class="mt-3" />
    </div>
</template>

<script>
import { format } from 'date-fns';
import { FormUtilError } from '@/components/form';
import CompanionCheckInToggle from './utils/CompanionCheckInToggle.vue';
import CompanionBulkCheckInToggle from './utils/CompanionBulkCheckInToggle.vue';

export default {
    name: 'BeyondCompanion',

    components: {
        FormUtilError,
        CompanionCheckInToggle,
        CompanionBulkCheckInToggle,
    },

    props: {
        participant: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isCheckingIn: false,
            errors: {},
        };
    },

    computed: {
        participantTickets() {
            return [...this.participant.projects, ...this.participant.tickets];
        },
    },

    methods: {
        getError(participantId) {
            return this.errors[participantId] ? this.errors[participantId][0] : '';
        },

        getFormattedDate(date) {
            return format(new Date(date), 'yyyy-MM-dd HH:mm');
        },

        onError(error) {
            this.errors = error.errors;
        },

        onToggleCheckIn(checkedIn, ticket) {
            if (ticket.ticketable_type === 'App\\Project') {
                const project = this.participant.projects.find(item => item.ticketable_id === ticket.ticketable_id);
                project.checked_in = checkedIn;
                project.checked_in_at = checkedIn ? this.getFormattedDate(new Date().toISOString()) : null;
            } else {
                const ticketItem = this.participant.tickets.find(item => item.ticketable_id === ticket.ticketable_id);
                ticketItem.checked_in = checkedIn;
                ticketItem.checked_in_at = checkedIn ? this.getFormattedDate(new Date().toISOString()) : null;
            }
        },

        onToggleCheckIns({ checkedIn, tickets }) {
            tickets.forEach(item => {
                if (item.ticketable_type === 'App\\Project') {
                    const projectItem = this.participant.projects.find(project => project.ticketable_id === item.ticketable_id);
                    projectItem.checked_in = checkedIn;
                    projectItem.checked_in_at = checkedIn ? this.getCheckedInTimestamp(projectItem) : null;
                } else {
                    const ticketItem = this.participant.tickets.find(ticket => ticket.ticketable_id === item.ticketable_id);
                    ticketItem.checked_in = checkedIn;
                    ticketItem.checked_in_at = checkedIn ? this.getCheckedInTimestamp(ticketItem) : null;
                }
            });
        },

        getCheckedInTimestamp(item) {
            let timestamp = item.checked_in_at;

            if (!timestamp) {
                timestamp = this.getFormattedDate(new Date().toISOString());
            }

            return timestamp;
        },
    },
};
</script>
