<template>
    <div class="bg-white text-xs rounded-lg shadow-sm">
        <div class="flexitems-center pt-4 pb-3.5 px-6 border-b border-gray-200">
            <h2 class="text-lg font-semibold text-gray-700 line-clamp-1">
                {{ title }}
            </h2>
        </div>

        <div class="pt-1 pb-6 px-6 text-base">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppWidget',

    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>
