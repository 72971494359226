<template>
    <form
        id="form"
        method="post"
        :novalidate="novalidate"
        @submit.prevent="$emit('submit')"
        @keydown="$emit('keydown', $event)"
    >
        <slot />
    </form>
</template>

<script>
export default {
    name: 'FormContainer',

    props: {
        novalidate: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
