<template>
    <div
        class="relative flex items-start py-3.5 pl-3 pr-2.5 text-sm bg-gray-800 text-white rounded-lg shadow-sm"
        :class="[backgroundColor]"
    >
        <div class="flex-1 flex" :class="[item.title ? 'items-start' : 'items-center']">
            <div class="ml-2.5">
                <div v-if="item.title" class="mb-0.5 font-bold leading-4" v-html="item.title" />
                <div class="font-semibold" v-html="item.message" />
            </div>
        </div>

        <div class="mt-1 ml-6 mr-2" :class="{ 'relative -top-1': item.title }">
            <button
                type="button"
                class="text-xs text-white/75 cursor-pointer focus:outline-none"
                @click.stop="$emit('close')"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppNotificationsFlash',

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    computed: {
        status() {
            return this.item.status;
        },

        backgroundColor() {
            const statuses = {
                success: 'bg-green-700',
                error: 'bg-red-900',
                warning: 'bg-yellow-600',
            };

            return statuses[this.status];
        },
    },
};
</script>
