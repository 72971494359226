<template>
    <ol class="list-decimal pl-3.5 space-y-2.5">
        <li v-for="(file, index) in files" :key="index">
            <div class="w-full break-all line-clamp-1">
                <a
                    :href="file.url"
                    class="link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {{ file.title }}
                </a>
            </div>
        </li>
    </ol>
</template>

<script>
export default {
    name: 'QuestionnaireFilesList',

    props: {
        files: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
