import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth';
import user from './modules/user';
import legacy from './modules/legacy';
import beyond from './modules/beyond';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,

    plugins: [
        createPersistedState({
            key: 'app',
            reducer: state => ({
                user: state.user,
                legacy: state.legacy,
                beyond: state.beyond,
                origin: state.origin,
            }),
        }),
        createPersistedState({
            key: 'auth',
            paths: ['auth'],
        }),
    ],

    modules: {
        auth,
        user,
        legacy,
        beyond,
    },

    state: {
        origin: 'eu', // 'eu', 'legacy', 'beyond'
    },

    getters: {
        //
    },

    mutations: {
        RESET_STATE(state) {
            state.origin = 'eu';
        },

        UPDATE_STATE_PROP(state, { prop, value }) {
            state[prop] = value;
        },
    },

    actions: {
        updateOrigin({ commit }, origin) {
            commit('UPDATE_STATE_PROP', { prop: 'origin', value: origin });
        },
    },
});
