import PageLegacyEvents from '@/pages/legacy/PageLegacyEvents.vue';
import PageLegacyBookingOptions from '@/pages/legacy/PageLegacyBookingOptions.vue';
import PageLegacyScanner from '@/pages/legacy/PageLegacyScanner.vue';
import PageLegacyCheckInAttendees from '@/pages/legacy/PageLegacyCheckInAttendees.vue';
import PageLegacyCheckInBookingOptions from '@/pages/legacy/PageLegacyCheckInBookingOptions.vue';
import PageLegacyAttendeesList from '@/pages/legacy/PageLegacyAttendeesList.vue';
import PageLegacyBookingOptionAttendeesList from '@/pages/legacy/PageLegacyBookingOptionAttendeesList.vue';
import PageLegacyAttendeeProfile from '@/pages/legacy/PageLegacyAttendeeProfile.vue';

import { authGuard } from '@/routes/guards';

export default [
    {
        name: 'legacy-events',
        path: '/events',
        component: PageLegacyEvents,
        beforeEnter: authGuard,
    },
    {
        name: 'legacy-booking-options',
        path: '/booking-options',
        component: PageLegacyBookingOptions,
        beforeEnter: authGuard,
    },
    {
        name: 'legacy-attendees-list',
        path: '/attendees',
        component: PageLegacyAttendeesList,
        beforeEnter: authGuard,
    },
    {
        name: 'legacy-booking-option-attendees-list',
        path: '/events/:eventId/options/:optionId/attendees',
        component: PageLegacyBookingOptionAttendeesList,
        props: true,
        beforeEnter: authGuard,
    },
    {
        name: 'legacy-attendee',
        path: '/attendees/:attendeeId',
        component: PageLegacyAttendeeProfile,
        props: true,
        beforeEnter: authGuard,
    },
    {
        name: 'legacy-scanner',
        path: '/scanner',
        component: PageLegacyScanner,
        beforeEnter: authGuard,
    },
    {
        name: 'legacy-check-in-attendees',
        path: '/check-in',
        component: PageLegacyCheckInAttendees,
        beforeEnter: authGuard,
    },
    {
        name: 'legacy-check-in-options',
        path: '/attendees/:attendeeId/check-in-options',
        component: PageLegacyCheckInBookingOptions,
        props: true,
        beforeEnter: authGuard,
    },
];
