import PageBeyondEvents from '@/pages/beyond/PageBeyondEvents.vue';
import PageBeyondTicketSelections from '@/pages/beyond/PageBeyondTicketSelections.vue';
import PageBeyondScanner from '@/pages/beyond/PageBeyondScanner.vue';
import PageBeyondAttendeesList from '@/pages/beyond/PageBeyondAttendeesList.vue';
import PageBeyondTicketsList from '@/pages/beyond/PageBeyondTicketsList.vue';
import PageBeyondAttendeeProfile from '@/pages/beyond/PageBeyondAttendeeProfile.vue';
import PageBeyondCheckInAttendees from '@/pages/beyond/PageBeyondCheckInAttendees.vue';
import PageBeyondCheckInOptions from '@/pages/beyond/PageBeyondCheckInOptions.vue';
import PageBeyondCheckInCompanions from '@/pages/beyond/PageBeyondCheckInCompanions.vue';

import { authGuard } from '@/routes/guards';

export default [
    {
        name: 'beyond-events',
        path: '/beyond/events',
        component: PageBeyondEvents,
        beforeEnter: authGuard,
    },
    {
        name: 'beyond-ticket-selections',
        path: '/beyond/events/:eventId/tickets',
        component: PageBeyondTicketSelections,
        props: true,
        beforeEnter: authGuard,
    },
    {
        name: 'beyond-scanner',
        path: '/beyond/events/:eventId/scanner',
        component: PageBeyondScanner,
        props: true,
        beforeEnter: authGuard,
    },
    {
        name: 'beyond-tickets-list',
        path: '/beyond/events/:eventId/tickets-list',
        component: PageBeyondTicketsList,
        props: true,
        beforeEnter: authGuard,
    },
    {
        name: 'beyond-attendees-list',
        path: '/beyond/events/:eventId/tickets/:ticketId/types/:ticketType/attendees',
        component: PageBeyondAttendeesList,
        props: true,
        beforeEnter: authGuard,
    },
    {
        name: 'beyond-attendee-profile',
        path: '/beyond/events/:eventId/attendees/:attendeeId',
        component: PageBeyondAttendeeProfile,
        props: true,
        beforeEnter: authGuard,
    },
    {
        name: 'beyond-check-in-attendees',
        path: '/beyond/events/:eventId/check-in',
        component: PageBeyondCheckInAttendees,
        props: true,
        beforeEnter: authGuard,
    },
    {
        name: 'beyond-check-in-options',
        path: '/beyond/events/:eventId/attendees/:attendeeId/check-in-options',
        component: PageBeyondCheckInOptions,
        props: true,
        beforeEnter: authGuard,
    },
    {
        name: 'beyond-check-in-companions',
        path: '/beyond/events/:eventId/attendees/:attendeeId/check-in-companions',
        component: PageBeyondCheckInCompanions,
        props: true,
        beforeEnter: authGuard,
    },
];
