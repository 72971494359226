<template>
    <app-page
        title="My Events"
        paddingless
        :loading="isLoading"
        :error="hasError"
        class="relative"
    >
        <app-no-items
            v-if="!hasEvents && !hasError"
            message="You don't have access to any events yet."
        />

        <template v-if="!hasError">
            <div class="sticky top-0 z-10 mx-2 pt-6 pb-4 px-4 bg-gray-200">
                <form-input
                    ref="search"
                    v-model="search"
                    size="lg"
                    name="search"
                    type="text"
                    theme="light"
                    search
                    placeholder="Search events..."
                    @clear="search = ''"
                />
            </div>

            <div class="p-6 pt-0">
                <app-card v-if="!hasFilteredEvents && search" class="text-sm font-semibold text-gray-600">
                    No events matching "<strong>{{ search }}</strong>" found.
                </app-card>

                <div v-if="hasFilteredEvents" class="mt-px space-y-4">
                    <div v-for="event in filteredEvents" :key="event.id">
                        <form-checkbox-button
                            :native-value="event.id"
                            show-check
                            :value="selectedEventIds"
                            @input="updateSelectedEventIds"
                        >
                            <div class="flex py-4">
                                <div class="flex-shrink-0 mt-1 ml-2.5">
                                    <img :src="event.image" alt="Event image" class="w-8 h-auto object-center object-cover rounded-lg">
                                </div>

                                <div class="flex flex-col ml-5 h-full">
                                    <div class="flex-1">
                                        <h2 class="text-base font-semibold leading-snug line-clamp-2">
                                            {{ event.name }}
                                        </h2>

                                        <span class="inline-block mt-1.5 text-xs text-gray-500">
                                            {{ getFormattedDate(event.date) }}
                                        </span>
                                    </div>

                                    <div class="mt-4 text-sm font-semibold text-gray-500">
                                        {{ event.ticketsData.tickedOffTicketsCount }} / {{ event.ticketsData.ticketsCount }} tickets used
                                    </div>
                                </div>
                            </div>
                        </form-checkbox-button>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="space-y-3">
                <app-button
                    appearance="primary"
                    size="lg"
                    class="w-full"
                    :disabled="!hasSelectedEvents"
                    @click="$router.push({ name: 'legacy-booking-options' })"
                >
                    {{ selectLabel }}
                </app-button>
            </div>
        </template>
    </app-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { format } from 'date-fns';

import { FormInput, FormCheckboxButton } from '@/components/form';

import { fetchEvents } from '@/api/legacy';

export default {
    name: 'PageLegacyEvents',

    components: {
        FormInput,
        FormCheckboxButton,
    },

    data() {
        return {
            isLoading: true,
            hasError: false,
            events: [],
            search: '',
        };
    },

    created() {
        fetchEvents()
            .then(response => {
                console.log('PageEvents (events): The events were fetched.', response.data.data.events);
                this.events = response.data.data.events;
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    computed: {
        ...mapState('legacy', ['selectedEventIds']),

        hasEvents() {
            return !!this.events.length;
        },

        hasFilteredEvents() {
            return !!this.filteredEvents.length;
        },

        sortedEvents() {
            return [...this.events].sort((a, b) => ((new Date(a.date) < new Date(b.date)) ? 1 : -1));
        },

        filteredEvents() {
            if (!this.search) return this.sortedEvents;

            const regex = new RegExp(`(${this.search})`, 'i');

            return this.sortedEvents.filter(event => event.name.match(regex));
        },

        hasSelectedEvents() {
            return !!this.selectedEventIds.length;
        },

        selectLabel() {
            return !this.hasSelectedEvents
                ? 'Please select at least one event'
                : 'To booking options';
        },
    },

    methods: {
        ...mapActions('legacy', ['updateSelectedEventIds']),

        getFormattedDate(date) {
            return format(new Date(date), 'MMMM do, yyyy');
        },
    },
};
</script>
