/* eslint-disable */
import Vue from 'vue';

import '@/core/plugins';
import '@/core/components';

import router from './routes';
import store from './store';
import App from './App.vue';
import './registerServiceWorker'

Vue.config.productionTip = false;

new Vue({
    name: 'Vue',
    router,
    store,
    render: h => h(App),
}).$mount('#app');
