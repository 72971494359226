<template>
    <app-page
        title="Check in attendees"
        paddingless
        :loading="isLoading"
        :error="hasError"
        class="relative"
    >
        <template v-if="!hasError">
            <div class="sticky top-0 z-10 mx-2 pt-6 pb-4 px-4 bg-gray-200">
                <form-input
                    ref="search"
                    v-model="search"
                    size="lg"
                    name="search"
                    type="text"
                    theme="light"
                    search
                    placeholder="Search attendees..."
                    @clear="clearSearch"
                />
            </div>

            <div class="p-6 pt-0">
                <app-card v-if="!hasAttendees" class="text-sm font-semibold text-gray-600">
                    No registered attendees were found.
                </app-card>

                <app-card v-if="!hasVisibleAttendees && search" class="text-sm font-semibold text-gray-600">
                    No attendees matching "<strong>{{ search }}</strong>" found.
                </app-card>

                <infinite-scroll
                    :items="attendees"
                    :last-page="pagination.pageCount"
                    class="space-y-2"
                    @refetch="fetchMoreAttendees"
                >
                    <template #item="{ item: attendee }">
                        <app-card
                            class="cursor-pointer"
                            @click.native="goToAttendeeProfile(attendee)"
                            @keydown.native="goToAttendeeProfile(attendee)"
                        >
                            <div class="flex justify-between items-center">
                                <div>
                                    <h2 class="text-lg font-semibold line-clamp-2">
                                        {{ attendee.fullName }}
                                    </h2>

                                    <span class="text-sm font-semibold text-gray-500">
                                        {{ attendee.ticketsData.tickedOffTicketsCount }} / {{ attendee.ticketsData.ticketsCount }} booking options
                                    </span>
                                </div>

                                <div class="ml-4 -mr-2.5">
                                    <app-checkbox-toggle
                                        :checked="isCheckedIn(attendee)"
                                        @click.native.stop="onCheckIn(attendee)"
                                    />
                                </div>
                            </div>
                        </app-card>
                    </template>
                </infinite-scroll>

                <app-loader v-if="isRefetching" class="mt-6 mb-2" />
            </div>
        </template>
    </app-page>
</template>

<script>
import debounce from 'lodash/debounce';

import { FormInput } from '@/components/form';
import InfiniteScroll from '@/components/utils/InfiniteScroll.vue';

import {
    fetchAttendees,
    checkInAttendee,
} from '@/api/legacy';

export default {
    name: 'PageLegacyCheckInAttendees',

    components: {
        FormInput,
        InfiniteScroll,
    },

    data() {
        return {
            isLoading: true,
            hasError: false,
            isFetching: false,
            isRefetching: false,
            isSubmitting: false,
            isSearch: false,
            attendees: [],
            search: '',
            pagination: {
                total: 0,
                page: 1,
                pageCount: 1,
            },
        };
    },

    created() {
        fetchAttendees(this.fetchUrl)
            .then(response => {
                console.log('PageCheckInAttendees (attendees): The attendees were fetched.', response.data.data);
                this.attendees = response.data.data.guests;
                this.setPaginationData(response.data.data);
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    watch: {
        search: debounce(function () {
            this.onSearch();
        }, 300),
    },

    computed: {
        hasAttendees() {
            return !!this.pagination.total;
        },

        hasVisibleAttendees() {
            return !!this.attendees.length;
        },

        fetchUrl() {
            return `&page=${this.pagination.page}&query=${this.search}`;
        },
    },

    methods: {
        fetchAttendees() {
            this.isFetching = true;

            fetchAttendees(this.fetchUrl)
                .then(response => {
                    console.log('PageBookingOptionAttendeesList (attendees): The attendees were fetched.', response.data.data);
                    this.attendees = response.data.data.guests;
                    this.setPaginationData(response.data.data);
                })
                .catch(error => {
                    if (error.response.status >= 500) {
                        this.hasError = true;
                    }
                })
                .finally(() => {
                    this.isFetching = false;
                });
        },

        fetchMoreAttendees(page) {
            this.pagination.page = page;
            if (this.pagination.page > this.pagination.pageCount) return;

            this.isRefetching = true;

            fetchAttendees(this.fetchUrl)
                .then(response => {
                    console.log('PageBookingOptionAttendeesList (attendees): More attendees were fetched.', response.data.data);
                    this.attendees.push(...response.data.data.guests);
                    this.pagination.pageCount = response.data.data.pageCount;
                })
                .catch(error => {
                    if (error.response.status >= 500) {
                        this.hasError = true;
                    }
                })
                .finally(() => {
                    this.isRefetching = false;
                });
        },

        setPaginationData(data) {
            this.pagination.page = data.page;
            this.pagination.pageCount = data.pageCount;

            if (this.isSearch) return;

            this.pagination.total = data.total;
        },

        goToAttendeeProfile(attendee) {
            this.$router.push({ name: 'legacy-attendee', params: { attendeeId: attendee.id } });
        },

        isCheckedIn(attendee) {
            return attendee.ticketsData?.tickedOffTicketsCount === attendee.ticketsData?.ticketsCount;
        },

        hasPreviousCheckIn(attendee) {
            return !!attendee.ticketsData?.tickedOffTicketsCount;
        },

        updateAttendeeTicketData(attendee) {
            attendee.ticketsData.tickedOffTicketsCount = attendee.ticketsData.ticketsCount;
        },

        onSearch() {
            this.isSearch = true;
            this.pagination.page = 1;

            this.fetchAttendees();
        },

        clearSearch() {
            this.search = '';
            this.isSearch = false;
            this.pagination.page = 1;

            this.fetchAttendees();
        },

        onCheckIn(attendee) {
            this.isSubmitting = true;

            if (this.hasPreviousCheckIn(attendee)) {
                this.$router.push({ name: 'legacy-check-in-options', params: { attendeeId: attendee.id } });
                return;
            }

            checkInAttendee(attendee.id)
                .then(() => {
                    this.updateAttendeeTicketData(attendee);
                })
                .catch(() => {
                    this.$notify({ message: 'It wasn\'t possible to check in the attendee.<br> Try again later.', status: 'error' });
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        },
    },
};
</script>
