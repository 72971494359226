<template>
    <app-page
        title="Attendees list"
        actionless
        :loading="isLoading"
        :error="hasError"
    >
        <div v-if="hasBookingOptions" class="space-y-6">
            <div v-for="event in events" :key="event.id">
                <h2 class="mb-3 text-lg text-gray-700 font-semibold line-clamp-2">
                    {{ event.name }}
                </h2>

                <div v-if="!event.priceLevels.length" class="text-sm text-gray-600">
                    There are no booking options available in this event.
                </div>

                <div v-if="event.priceLevels.length" class="grid grid-cols-1 gap-2">
                    <div v-for="option in event.priceLevels" :key="option.id">
                        <router-link
                            :to="{ name: 'legacy-booking-option-attendees-list', params: { eventId: event.id, optionId: option.id } }"
                            class="block"
                        >
                            <app-card>
                                <div class="flex justify-between items-center">
                                    <div class="flex py-1">
                                        <div class="flex flex-col ml-2.5 h-full">
                                            <div class="flex-1">
                                                <div class="text-lg font-semibold line-clamp-2">
                                                    {{ option.name }}
                                                </div>
                                            </div>

                                            <div class="mt-1 text-sm font-semibold text-gray-500">
                                                <template v-if="hasTickets(option)">
                                                    {{ option.ticketsData.tickedOffTicketsCount }} / {{ option.ticketsData.ticketsCount }} checked in
                                                </template>
                                                <template v-else>
                                                    No tickets to check in
                                                </template>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="ml-4">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-7 w-7 text-gray-600"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            stroke-width="2"
                                        >
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>
                                </div>
                            </app-card>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </app-page>
</template>

<script>
import { fetchBookingOptions } from '@/api/legacy';

export default {
    name: 'PageLegacyAttendeesList',

    data() {
        return {
            isLoading: true,
            hasError: false,
            events: [],
        };
    },

    created() {
        fetchBookingOptions()
            .then(response => {
                console.log('PageAttendeesList (events): The events were fetched.', response.data.data.events);
                this.events = response.data.data.events;
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    computed: {
        hasBookingOptions() {
            return !!this.events.flatMap(event => event.priceLevels).length;
        },
    },

    methods: {
        hasTickets(option) {
            return !!option.ticketsData.ticketsCount;
        },
    },
};
</script>
