<template>
    <app-card>
        <div class="text-sm font-semibold text-gray-700" v-html="message" />
    </app-card>
</template>

<script>
export default {
    name: 'AppNoItems',

    props: {
        message: {
            type: String,
            required: true,
        },
    },
};
</script>
