<template>
    <div
        class="py-5 px-6 text-sm font-semibold leading-relaxed border border-red-800 rounded-lg"
        :class="[solid ? 'bg-red-800 text-white' : 'bg-red-800/10 text-red-800']"
    >
        <template v-if="!message">
            <p>
                It was not possible to load the content at the moment.
            </p>

            <button
                type="button"
                class="mt-3 font-bold"
                @click="reload"
            >
                Try again?
            </button>
        </template>

        <div v-if="message" v-html="message" />
    </div>
</template>

<script>
export default {
    name: 'AppError',

    props: {
        message: {
            type: String,
            required: false,
        },

        solid: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        reload() {
            window.location.reload();
        },
    },
};
</script>
