<template>
    <div class="relative flex flex-col h-full bg-gray-200">
        <div class="flex flex-shrink-0 justify-between items-center px-6 h-16 bg-brand-600">
            <div class="w-24 text-xs font-semibold text-white text-opacity-80">
                <button
                    v-if="!['events', 'beyond-events'].includes($route.name)"
                    type="button"
                    class="-ml-2.5 p-2 rounded-full"
                    @click.prevent="$router.back()"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                </button>
            </div>

            <h1 v-if="title" class="text-base font-bold text-white text-center leading-tight">
                {{ title }}
            </h1>

            <div class="w-24 text-xs font-semibold text-white text-opacity-80 text-right">
                <slot v-if="!actionless" name="actions">
                    <button
                        type="button"
                        class="-mr-3 p-2 rounded-full"
                        @click.prevent="logout"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                    </button>
                </slot>
            </div>
        </div>

        <app-loader v-if="loading && showLoader" class="absolute top-32 left-0 right-0" />

        <transition name="fade-in-up">
            <div v-if="!loading" class="flex-1 flex flex-col overflow-y-hidden">
                <app-error v-if="error" class="m-6" />

                <div v-if="$slots.default" class="flex-1 overflow-y-auto" :class="{ 'p-6': !paddingless }">
                    <slot />
                </div>

                <div v-if="$slots.footer" class="p-6 pt-0">
                    <slot name="footer" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'AppPage',

    props: {
        title: {
            type: String,
        },

        paddingless: {
            type: Boolean,
            default: false,
        },

        actionless: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        error: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            showLoader: false,
        };
    },

    watch: {
        loading: {
            handler(loading) {
                if (!loading) {
                    this.showLoader = false;
                } else {
                    setTimeout(() => {
                        this.showLoader = true;
                    }, 300);
                }
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions('auth', ['logout']),
    },
};
</script>
