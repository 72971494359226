<template>
    <div
        class="inline-flex items-center py-2 px-3.5 text-sm font-bold text-white text-center leading-4 rounded-full"
        :style="{ background: `#${tag.color}` }"
    >
        <span class="font-bold">{{ tag.title }}</span>
    </div>
</template>

<script>
export default {
    name: 'BeyondAttendeeTags',

    props: {
        tag: {
            type: Object,
            required: true,
        },
    },
};
</script>
