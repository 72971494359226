<template>
    <app-page title="QR Scanner" paddingless class="relative">
        <template #actions>
            <button
                type="button"
                class="-mr-2 p-2 rounded-full"
                @click.prevent="$router.push({ name: 'legacy-attendees-list' })"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
            </button>
        </template>

        <qr-scanner v-if="isVisibleScanner" @decode="onDecode" />

        <div v-if="isCheckedIn" class="absolute inset-0 bg-black bg-opacity-50" />

        <app-error v-if="error" :message="error" solid class="absolute z-10 top-24 left-6 right-6" />

        <div
            v-if="isCheckedIn"
            class="absolute z-10 top-40 left-12 right-12 flex items-center flex-col"
        >
            <div class="flex flex-shrink-0 justify-center items-center w-28 h-28 bg-green-600 border-green-600 rounded-full">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-16 w-16 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
            </div>

            <div class="mt-6 text-lg font-semibold text-white">
                Checked in!
            </div>

            <app-button
                v-if="isCheckedIn && attendeeId"
                appearance="primary"
                size="lg"
                class="mt-10 w-60"
                @click="$router.push({ name: 'legacy-attendee', params: { attendeeId } })"
            >
                Visit attendee profile
            </app-button>

            <app-button
                v-if="isCheckedIn && attendeeId"
                appearance="outline"
                size="lg"
                class="mt-4 w-60 opacity-75"
                @click="reloadScanner"
            >
                New scan
            </app-button>
        </div>

        <div class="fixed bottom-0 left-0 right-0 p-6">
            <app-button
                appearance="primary"
                size="lg"
                class="w-full"
                @click="$router.push({ name: 'legacy-check-in-attendees' })"
            >
                Manual check in
            </app-button>
        </div>
    </app-page>
</template>

<script>
import { isValidUUIDV4 } from 'is-valid-uuid-v4';

import QrScanner from '@/components/wrappers/QrScanner.vue';

import { checkInAttendee } from '@/api/legacy';

export default {
    name: 'PageLegacyScanner',

    components: {
        QrScanner,
    },

    data() {
        return {
            isVisibleScanner: true,
            isCheckedIn: false,
            attendeeId: '',
            error: '',
            result: '',
            errorMessage: {
                invalid: 'This is not a valid attendee QR code.<br><br> Press <strong>"Manual check in"</strong> to check in the attendee manually.',
                notFound: 'The attendee was not found.<br><br> Press <strong>"Manual check in"</strong> to check in the attendee manually.',
                failed: 'It was not possible to check in the attendee.<br><br> Press <strong>"Manual check in"</strong> to check in the attendee manually instead.',
            },
        };
    },

    methods: {
        reloadScanner() {
            this.isVisibleScanner = false;
            this.isCheckedIn = false;
            this.attendeeId = '';

            setTimeout(() => {
                this.isVisibleScanner = true;
            }, 50);
        },

        onDecode(attendeeId) {
            if (!isValidUUIDV4(attendeeId)) {
                this.error = this.errorMessage.invalid;
                return;
            }

            this.onCheckIn(attendeeId);
        },

        onError(attendeeId, error) {
            const errorCode = error.response.data.messages.error[0];

            switch (errorCode) {
                case 'APP_TICK_OFF_MSG_GUEST_NOT_FOUND':
                    this.error = this.errorMessage.notFound;
                    break;
                case 'APP_TICK_OFF_MSG_FAIL':
                    this.error = this.errorMessage.failed;
                    break;
                case 'APP_TICK_OFF_MSG_MANUAL_CHECKIN_REQUIRED':
                    this.$router.push({ name: 'legacy-check-in-options', params: { attendeeId }, query: { events: this.eventIds, options: this.optionIds } });
                    break;
                default:
                    console.log('Unknown error code.');
            }
        },

        onCheckIn(attendeeId) {
            checkInAttendee(attendeeId)
                .then(() => {
                    this.isCheckedIn = true;
                    this.attendeeId = attendeeId;
                })
                .catch(error => {
                    this.onError(attendeeId, error);
                });
        },
    },
};
</script>
