<template>
    <qrcode-stream :camera="cameraFacingMode" class="relative w-full h-full" @decode="onDecode" @init="onInit">
        <app-loader v-if="isLoading" class="mt-12" />

        <app-error v-if="error" :message="error" class="absolute z-10 top-6 left-6 right-6" />

        <div v-if="showReloadButton && error" class="absolute flex justify-center z-10 top-28 w-full">
            <app-button
                appearance="primary"
                class="font-bold"
                @click="onReset"
            >
                Reset camera
            </app-button>
        </div>
    </qrcode-stream>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    name: 'QrScanner',

    components: {
        QrcodeStream,
    },

    props: {
        cameraFacingMode: {
            type: String,
            default: 'auto',
        },

        hasOverconstrainedError: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: false,
            init: false,
            error: '',
            errorCode: '',
            showReloadButton: false,
        };
    },

    watch: {
        init(init) {
            if (!init) return;

            this.onInit();
        },
    },

    methods: {
        onReset() {
            this.$emit('reset');
            this.error = '';
            this.$emit('update:hasOverconstrainedError', false);
            this.showReloadButton = false;
        },

        onDecode(result) {
            this.$emit('decode', result);
        },

        async onInit(promise) {
            this.isLoading = true;

            try {
                await promise;
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = 'You need to grant camera access permission.';
                    this.errorCode = 'NotAllowedError';
                } else if (error.name === 'NotFoundError') {
                    this.error = 'The current device doesn\'t have a camera.';
                } else if (error.name === 'NotSupportedError') {
                    this.error = 'Secure context required (HTTPS, localhost).';
                } else if (error.name === 'NotReadableError') {
                    this.error = 'Is the camera already in use?';
                } else if (error.name === 'OverconstrainedError') {
                    this.error = 'Installed cameras are not suitable.';
                    this.$emit('update:hasOverconstrainedError', true);
                    this.showReloadButton = true;
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = 'Stream API is not supported in this browser.';
                } else if (error.name === 'InsecureContextError') {
                    this.error = 'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    this.error = `Camera error (${error.name}).`;
                }
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>
