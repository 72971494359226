import store from '@/store';

export function fetch(route) {
    return window.axios.get('/api/proxy/check-in/get', {
        params: {
            origin: store.state.origin,
            token: store.state.auth.token,
            route,
        },
    });
}

export function post(route) {
    return window.axios.post('/api/proxy/check-in/post', {
        origin: store.state.origin,
        token: store.state.auth.token,
        route,
    });
}
