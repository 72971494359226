<template>
    <app-page
        title="Attendee Profile"
        :loading="isLoading"
        :error="hasError"
    >
        <div v-if="!hasError" class="space-y-4">
            <app-widget :title="attendee.full_name">
                <beyond-attendee-details :attendee="attendee" />
            </app-widget>

            <div class="mt-4 space-y-4">
                <app-widget title="Check-in status">
                    <beyond-attendee-event-details :events="events" />
                </app-widget>

                <app-widget v-if="!!tags.length" title="Tags">
                    <div class="flex items-center flex-wrap mt-5 -mb-3">
                        <beyond-attendee-tags
                            v-for="tag in tags"
                            :key="tag.id"
                            :tag="tag"
                            class="mb-3 mr-3"
                        />
                    </div>
                </app-widget>

                <app-widget v-if="!!questionnaireFields.length" title="Questionnaire answers">
                    <div class="mt-5">
                        <ul class="list-reset space-y-5">
                            <beyond-attendee-questionnaire-answer
                                v-for="field in questionnaireFields"
                                :key="field.id"
                                :field="field"
                                :answer="getAnswer(field)"
                            />
                        </ul>
                    </div>
                </app-widget>

                <app-widget v-if="!!resources.length" title="Resources">
                    <div class="mt-5 space-y-10">
                        <beyond-attendee-resource
                            v-for="resource in resources"
                            :key="resource.id"
                            :resource="resource"
                            class="pb-10 border-b border-dashed border-gray-250 last:border-b-0"
                        />
                    </div>
                </app-widget>
            </div>
        </div>
    </app-page>
</template>

<script>
import { fetchAttendee } from '@/api/beyond';

import BeyondAttendeeDetails from './components/BeyondAttendeeDetails.vue';
import BeyondAttendeeEventDetails from './components/BeyondAttendeeEventDetails.vue';
import BeyondAttendeeTags from './components/BeyondAttendeeTags.vue';
import BeyondAttendeeQuestionnaireAnswer from './components/BeyondAttendeeQuestionnaireAnswer.vue';
import BeyondAttendeeResource from './components/BeyondAttendeeResource.vue';

export default {
    name: 'PageBeyondAttendeeProfile',

    components: {
        BeyondAttendeeDetails,
        BeyondAttendeeEventDetails,
        BeyondAttendeeTags,
        BeyondAttendeeQuestionnaireAnswer,
        BeyondAttendeeResource,
    },

    props: {
        eventId: {
            type: String,
            required: true,
        },

        attendeeId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isLoading: true,
            isCheckingIn: false,
            hasError: false,
            attendee: '',
            events: [],
            tags: [],
            questionnaireFields: [],
            questionnaireAnswers: [],
            resources: [],
        };
    },

    created() {
        fetchAttendee(this.eventId, this.attendeeId)
            .then(response => {
                console.log('PageAttendeeProfile (attendee): The attendee was fetched.', response.data.data);
                this.attendee = response.data.data.guest;
                this.events = response.data.data.events;
                this.tags = response.data.data.tags;
                this.questionnaireFields = response.data.data.questionnaireFields;
                this.questionnaireAnswers = response.data.data.questionnaireAnswers;
                this.resources = response.data.data.resources;
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    methods: {
        getAnswer(field) {
            const answer = this.questionnaireAnswers.find(item => item.field_id === field.id);

            if (field.type === 'file') {
                return answer || { files: [] };
            }

            return answer;
        },
    },
};
</script>
