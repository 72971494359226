<template>
    <label
        ref="label"
        :for="id"
        :class="[
            [group ? 'flex' : 'inline-flex'],
            [group && !error ? 'items-center' : 'items-start'],
            { 'items-start': group && error },
            { 'flex-col': error },
            { 'cursor-not-allowed': disabled },
        ]"
        @keydown.prevent.enter.space="$refs.label.click()"
    >
        <div class="flex items-start">
            <input
                ref="checkbox"
                v-model="newValue"
                :value="nativeValue"
                :true-value="trueValue"
                :false-value="falseValue"
                :name="name"
                :required="required"
                :disabled="disabled"
                :tabindex="disabled ? false : 0"
                type="checkbox"
                class="form-checkbox relative"
                :class="[group ? 'top-0.5' : '-top-px']"
                @change="$emit('change')"
                @click="$emit('click')"
            >
            <div class="text-xs font-semibold text-gray-700" :class="[!group ? 'ml-2.5' : 'ml-4']">
                <slot />

                <form-util-error v-if="error" :error="error" />
            </div>
        </div>
    </label>
</template>

<script>
import FormUtilError from './utils/FormUtilError.vue';

export default {
    name: 'FormCheckbox',

    components: {
        FormUtilError,
    },

    props: {
        value: [String, Number, Boolean, Function, Object, Array, Symbol],
        nativeValue: [String, Number, Boolean, Function, Object, Array, Symbol],
        id: String,
        name: String,
        type: String,
        required: Boolean,
        disabled: Boolean,
        group: Boolean,
        checked: Boolean,
        indeterminate: Boolean,
        error: String,

        trueValue: {
            type: [String, Number, Boolean, Function, Object, Array, Symbol],
            default: true,
        },

        falseValue: {
            type: [String, Number, Boolean, Function, Object, Array, Symbol],
            default: false,
        },
    },

    data() {
        return {
            newValue: this.value,
        };
    },

    watch: {
        value(value) {
            this.newValue = value;
        },

        newValue(value) {
            this.$emit('input', value);
        },

        checked(checked) {
            this.$refs.checkbox.checked = checked;
        },

        indeterminate(indeterminate) {
            this.$refs.checkbox.indeterminate = indeterminate;
            this.$refs.checkbox.checked = false;
        },
    },
};
</script>
