export const ACCESS_TOKEN = 'auth';
export const PERSISTED_STATE = 'app';

export function getAccessToken() {
    const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
    return token && token.auth.token;
}

export function clearAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN);
}

export function isLoggedIn() {
    return getAccessToken();
}
