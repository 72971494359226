<template>
    <app-page
        title="Booking options"
        paddingless
        :loading="isLoading"
        :error="hasError"
        class="relative"
    >
        <app-no-items
            v-if="!hasBookingOptions && !hasError"
            message="There are no booking options available in the selected events."
        />

        <template v-if="!hasError">
            <div class="sticky top-0 z-10 mx-2 pt-6 pb-4 px-4 bg-gray-200">
                <form-input
                    ref="search"
                    v-model="search"
                    size="lg"
                    name="search"
                    type="text"
                    theme="light"
                    search
                    placeholder="Search options..."
                    @clear="search = ''"
                />
            </div>

            <div class="p-6 pt-0">
                <app-card v-if="!hasFilteredBookingOptions && search" class="text-sm font-semibold text-gray-600">
                    No booking options matching "<strong>{{ search }}</strong>" found.
                </app-card>

                <div v-if="hasFilteredBookingOptions" class="space-y-6">
                    <template v-for="event in events">
                        <div v-if="!event.priceLevels.length" :key="event.id" class="text-sm text-gray-600">
                            There are no booking options available in this event.
                        </div>

                        <div v-if="getBookingOptions(event.id).length" :key="event.id">
                            <h1 class="mb-3 text-lg text-gray-700 font-semibold line-clamp-2">
                                {{ event.name }}
                            </h1>

                            <div class="grid grid-cols-1 gap-2">
                                <div v-for="option in getBookingOptions(event.id)" :key="option.id">
                                    <form-checkbox-button
                                        :native-value="option.id"
                                        :disabled="!hasTickets(option)"
                                        show-check
                                        :value="selectedBookingOptionIds"
                                        @input="updateSelectedBookingOptionIds"
                                    >
                                        <div class="flex py-4">
                                            <div class="flex flex-col ml-2.5 h-full">
                                                <div class="flex-1">
                                                    <div class="text-lg font-semibold line-clamp-2">
                                                        {{ option.name }}
                                                    </div>
                                                </div>

                                                <div class="mt-1 text-sm font-semibold text-gray-500">
                                                    <template v-if="hasTickets(option)">
                                                        {{ option.ticketsData.tickedOffTicketsCount }} / {{ option.ticketsData.ticketsCount }} checked in
                                                    </template>
                                                    <template v-else>
                                                        No tickets to check in
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </form-checkbox-button>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>

        <template #footer>
            <div v-if="hasBookingOptions" class="space-y-3">
                <app-button
                    appearance="primary"
                    size="lg"
                    class="w-full"
                    :disabled="!hasSelectedOptions"
                    @click="$router.push({ name: 'legacy-scanner' })"
                >
                    {{ checkInLabel }}
                </app-button>
            </div>
        </template>
    </app-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { FormInput, FormCheckboxButton } from '@/components/form';

import { fetchBookingOptions } from '@/api/legacy';

export default {
    name: 'PageLegacyBookingOptions',

    components: {
        FormInput,
        FormCheckboxButton,
    },

    data() {
        return {
            isLoading: true,
            hasError: false,
            events: [],
            search: '',
        };
    },

    created() {
        fetchBookingOptions()
            .then(response => {
                console.log('PageBookingOptions (events): The events were fetched.', response.data.data.events);
                this.events = response.data.data.events;
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    computed: {
        ...mapState('legacy', ['selectedBookingOptionIds']),

        hasBookingOptions() {
            return !!this.events.flatMap(event => event.priceLevels).length;
        },

        hasFilteredBookingOptions() {
            return !!this.filteredBookingOptions.length;
        },

        hasSelectedOptions() {
            return !!this.selectedBookingOptionIds.length;
        },

        checkInLabel() {
            return !this.hasSelectedOptions
                ? 'Please select at least one option'
                : 'To check in';
        },

        bookingOptions() {
            return this.events.flatMap(event => event.priceLevels.map(option => ({
                eventId: event.id,
                ...option,
            })));
        },

        filteredBookingOptions() {
            if (!this.search) return this.bookingOptions;

            const regex = new RegExp(`(${this.search})`, 'i');

            return this.bookingOptions.filter(option => option.name.match(regex));
        },
    },

    methods: {
        ...mapActions('legacy', ['updateSelectedBookingOptionIds']),

        hasTickets(option) {
            return !!option.ticketsData.ticketsCount;
        },

        getBookingOptions(eventId) {
            return this.filteredBookingOptions.filter(option => option.eventId === eventId);
        },
    },
};
</script>
