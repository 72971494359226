<template>
    <app-page
        title="Attendees list"
        actionless
        paddingless
        :loading="isLoading"
        :error="hasError"
        class="relative"
    >
        <template v-if="!hasError">
            <div class="sticky top-0 z-10 mx-2 pt-6 pb-4 px-4 bg-gray-200">
                <form-input
                    ref="search"
                    v-model="search"
                    size="lg"
                    name="search"
                    type="text"
                    theme="light"
                    search
                    placeholder="Search attendees..."
                    @clear="clearSearch"
                />
            </div>

            <div v-if="!isLoading" class="p-6 pt-0 space-y-4">
                <app-widget :title="eventName">
                    <h3 class="mt-4 text-base font-semibold line-clamp-2">
                        {{ option.name }}
                    </h3>

                    <div class="mt-1 text-sm font-semibold text-gray-500">
                        {{ option.ticketsData.tickedOffTicketsCount }} / {{ option.ticketsData.ticketsCount }} tickets used
                    </div>
                </app-widget>

                <app-card v-if="!hasAttendees" class="text-sm font-semibold text-gray-600">
                    No registered attendees were found.
                </app-card>

                <app-card v-if="!hasVisibleAttendees && search" class="text-sm font-semibold text-gray-600">
                    No attendees matching "<strong>{{ search }}</strong>" found.
                </app-card>

                <app-card v-if="hasAttendees && hasVisibleAttendees">
                    <infinite-scroll
                        :items="attendees"
                        :last-page="pagination.pageCount"
                        class="space-y-4"
                        item-class="pt-4 first:pt-0 border-t first:border-t-0 border-gray-200"
                        @refetch="fetchMoreAttendees"
                    >
                        <template #item="{ item: attendee }">
                            <legacy-attendee-item :attendee="attendee" />
                        </template>
                    </infinite-scroll>
                </app-card>

                <app-loader v-if="isRefetching" class="py-3" />
            </div>
        </template>
    </app-page>
</template>

<script>
import debounce from 'lodash/debounce';

import { FormInput } from '@/components/form';
import InfiniteScroll from '@/components/utils/InfiniteScroll.vue';

import {
    fetchBookingOptions,
    fetchBookingOptionAttendees,
} from '@/api/legacy';

import LegacyAttendeeItem from './components/LegacyAttendeeItem.vue';

export default {
    name: 'PageLegacyBookingOptionAttendeesList',

    components: {
        FormInput,
        InfiniteScroll,
        LegacyAttendeeItem,
    },

    props: {
        eventId: {
            type: String,
            required: true,
        },

        optionId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isLoading: true,
            isFetching: false,
            isRefetching: false,
            isSearch: false,
            hasError: false,
            eventName: '',
            option: '',
            attendees: [],
            search: '',
            pagination: {
                total: 0,
                page: 1,
                pageCount: 1,
            },
        };
    },

    created() {
        Promise.all([
            fetchBookingOptions(this.eventsUrl),
            fetchBookingOptionAttendees(this.fetchAttendeesUrl),
        ])
            .then(([events, attendees]) => {
                console.log('PageBookingOptionAttendeesList (events): The events were fetched.', events.data.data);
                console.log('PageBookingOptionAttendeesList (attendees): The attendees were fetched.', attendees.data.data);
                this.eventName = events.data.data.events[0].name;
                this.setBookingOption(events.data.data);
                this.attendees = attendees.data.data.guests;
                this.setPaginationData(attendees.data.data);
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    watch: {
        search: debounce(function () {
            this.onSearch();
        }, 300),
    },

    computed: {
        hasAttendees() {
            return !!this.pagination.total;
        },

        hasVisibleAttendees() {
            return !!this.attendees.length;
        },

        eventsUrl() {
            return `sltEvents[]=${this.eventId}`;
        },

        fetchAttendeesUrl() {
            return `sltPriceLevels[]=${this.optionId}&page=${this.pagination.page}&query=${this.search}`;
        },
    },

    methods: {
        fetchAttendees() {
            this.isFetching = true;

            fetchBookingOptionAttendees(this.fetchAttendeesUrl)
                .then(response => {
                    console.log('PageBookingOptionAttendeesList (attendees): The attendees were fetched.', response.data.data);
                    this.attendees = response.data.data.guests;
                    this.setPaginationData(response.data.data);
                })
                .catch(error => {
                    if (error.response.status >= 500) {
                        this.hasError = true;
                    }
                })
                .finally(() => {
                    this.isFetching = false;
                });
        },

        fetchMoreAttendees(page) {
            this.pagination.page = page;
            if (this.pagination.page > this.pagination.pageCount) return;

            this.isRefetching = true;

            fetchBookingOptionAttendees(this.fetchAttendeesUrl)
                .then(response => {
                    console.log('PageBookingOptionAttendeesList (attendees): More attendees were fetched.', response.data.data);
                    this.attendees.push(...response.data.data.guests);
                    this.pagination.pageCount = response.data.data.pageCount;
                })
                .catch(error => {
                    if (error.response.status >= 500) {
                        this.hasError = true;
                    }
                })
                .finally(() => {
                    this.isRefetching = false;
                });
        },

        onSearch() {
            this.isSearch = true;
            this.pagination.page = 1;

            this.fetchAttendees();
        },

        clearSearch() {
            this.search = '';
            this.isSearch = false;
            this.pagination.page = 1;

            this.fetchAttendees();
        },

        setBookingOption(options) {
            this.option = options.events[0].priceLevels.find(option => option.id === this.optionId);
        },

        setPaginationData(data) {
            this.pagination.page = data.page;
            this.pagination.pageCount = data.pageCount;

            if (this.isSearch) return;

            this.pagination.total = data.total;
        },
    },
};
</script>
