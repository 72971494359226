import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
    namespaced: true,

    state: {
        selectedTickets: [],
    },

    getters: {
        selectedTickets: state => state.selectedTickets,
    },

    mutations: {
        RESET_STATE(state) {
            state.selectedTickets = [];
        },

        UPDATE_STATE_PROP(state, { prop, value }) {
            state[prop] = value;
        },
    },

    actions: {
        updateSelectedTickets({ commit }, tickets) {
            commit('UPDATE_STATE_PROP', { prop: 'selectedTickets', value: tickets });
        },
    },
};
