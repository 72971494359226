import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
    namespaced: true,

    state: {
        id: '',
        name: '',
        email: '',
    },

    mutations: {
        RESET_STATE(state) {
            state.id = '';
            state.name = '';
            state.email = '';
        },

        UPDATE_USER(state, user) {
            state.id = user.id;
            state.name = user.name;
            state.email = user.email;
        },
    },

    actions: {
        updateUser({ commit }, user) {
            commit('UPDATE_USER', user);
        },
    },
};
