import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
    namespaced: true,

    state: {
        selectedEventIds: [],
        selectedBookingOptionIds: [],
    },

    mutations: {
        RESET_STATE(state) {
            state.selectedEventIds = [];
            state.selectedBookingOptionIds = [];
        },

        UPDATE_STATE_PROP(state, { prop, value }) {
            state[prop] = value;
        },
    },

    actions: {
        updateSelectedEventIds({ commit }, eventIds) {
            commit('UPDATE_STATE_PROP', { prop: 'selectedEventIds', value: eventIds });
            commit('UPDATE_STATE_PROP', { prop: 'selectedBookingOptionIds', value: [] });
        },

        updateSelectedBookingOptionIds({ commit }, bookingOptionIds) {
            commit('UPDATE_STATE_PROP', { prop: 'selectedBookingOptionIds', value: bookingOptionIds });
        },
    },
};
