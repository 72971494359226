<template>
    <div id="app" class="w-full h-full bg-gray-200">
        <div v-if="isAuthenticated" class="h-full">
            <router-view />
        </div>

        <div v-if="!isAuthenticated" class="h-full">
            <router-view name="index" />
        </div>

        <app-notifications group="flash" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'App',

    created() {
        this.checkTokenValidity();
    },

    computed: {
        ...mapGetters('auth', [
            'isAuthenticated',
        ]),
    },

    methods: {
        ...mapActions('auth', [
            'clearAll',
        ]),

        checkTokenValidity() {
            if (this.isAuthenticated || this.$route.name === 'login') return;

            this.clearAll();
        },
    },
};
</script>
