<template>
    <div class="notification-group" :class="group" :style="groupStyles">
        <transition-group :name="animationName" class="space-y-1.5">
            <template v-for="item in activeItems">
                <component
                    :is="notificationComponent"
                    :key="item.id"
                    :data-id="item.id"
                    :item="item"
                    @close="closeItem(item.id)"
                />
            </template>
        </transition-group>
    </div>
</template>

<script>
import defaults from './defaults';
import events from './events';

import AppNotificationsFlash from './components/AppNotificationsFlash.vue';

export default {
    name: 'AppNotifications',

    components: {
        AppNotificationsFlash,
    },

    props: {
        group: {
            type: String,
            default: defaults.type.flash,
        },

        duration: {
            type: Number,
            required: false,
            default: defaults.duration,
        },

        reverse: {
            type: Boolean,
            default: defaults.reverse,
        },
    },

    data() {
        return {
            items: [],
        };
    },

    mounted() {
        events.$on('add', this.addItem);
        events.$on('close', this.closeItem);
    },

    computed: {
        groupStyles() {
            const styles = {
                flash: {
                    left: '1.5rem',
                    right: '1.5rem',
                    bottom: '1.5rem',
                },
                notification: {
                    top: '69px',
                    right: '1rem',
                },
                announcement: {
                    top: '1rem',
                    left: '50%',
                    'margin-left': '-160px',
                },
            };

            return styles[this.group];
        },

        notificationComponent() {
            const components = {
                flash: 'AppNotificationsFlash',
                notification: 'AppNotificationsNotification',
                announcement: 'AppNotificationsPublicAnnouncement',
            };

            return components[this.group];
        },

        animationName() {
            const groups = {
                flash: 'flash-animation',
                notification: 'notification-animation',
                announcement: 'announcement-animation',
            };

            return groups[this.group];
        },

        activeItems() {
            return this.items.filter(v => v.state !== defaults.itemState.destroyed);
        },
    },

    methods: {
        addItem(event) {
            event.group = event.type || defaults.type.flash;
            if (this.group !== event.group) return;

            const {
                id,
                type,
                status = defaults.itemStatus.success,
                title,
                message,
                data,
                timer = true,
            } = event;

            const item = {
                id: id || Date.now(),
                type,
                status,
                title,
                message,
                data,
                state: defaults.itemState.active,
            };

            const hasTimer = type !== defaults.type.announcement ? timer : false;

            if (hasTimer) {
                item.timer = setTimeout(() => {
                    this.destroy(item);
                }, this.duration);
            }

            !this.reverse ? this.items.unshift(item) : this.items.push(item);
        },

        destroy(item) {
            clearTimeout(item.timer);
            item.state = defaults.itemState.destroyed;
        },

        destroyById(itemId) {
            const item = this.items.find(listItem => listItem.id === itemId);

            if (item) this.destroy(item);
        },

        closeItem(id) {
            this.destroyById(id);
        },
    },
};
</script>

<style scoped>
.notification-group {
    @apply fixed z-150 block;
}

.notification-group.flash {
    min-width: 250px;
    width: calc(100% - 3rem);
}

.notification-group.notification {
    width: 300px;
}

.notification-group.announcement {
    width: 320px;
}

.notification-template {
    @apply block bg-white text-left;
}

.flash-animation-enter,
.flash-animation-leave-to {
    @apply opacity-0;
    transform: translateY(50px);
}

.flash-animation-enter-active,
.flash-animation-leave-active {
    @apply transition duration-500 ease-in-out;
}

.notification-animation-enter,
.notification-animation-leave-to {
    @apply opacity-0;
    transform: translateX(100%);
}

.notification-animation-enter-active,
.notification-animation-leave-active {
    @apply transition duration-500 ease-in-out;
}

.announcement-animation-enter,
.announcement-animation-leave-to {
    @apply opacity-0;
    transform: translateY(-20px);
}

.announcement-animation-enter-active,
.announcement-animation-leave-active {
    @apply transition duration-500 ease-in-out;
}
</style>
