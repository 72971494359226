<template>
    <div class="mb-2 ml-px font-semibold text-gray-700" :class="classes">
        <slot />
        <span v-if="required">*</span>
    </div>
</template>

<script>
export default {
    name: 'FormUtilLabel',

    props: {
        size: {
            type: String,
            default: 'default',
        },

        required: {
            type: Boolean,
            required: false,
        },
    },

    computed: {
        classes() {
            const classes = {
                default: 'text-xs',
                lg: 'px-0.5 text-sm',
            };

            return classes[this.size];
        },
    },
};
</script>
