<template>
    <app-page
        title="My Active Events"
        paddingless
        :loading="isLoading"
        :error="hasError"
        class="relative"
    >
        <div v-if="!hasEvents && !hasError" class="p-6 pb-0">
            <app-no-items message="You don't have access to any active events yet." />
        </div>

        <template v-if="hasEvents && !hasError">
            <div class="sticky top-0 z-10 mx-2 pt-6 pb-4 px-4 bg-gray-200">
                <form-input
                    ref="search"
                    v-model="search"
                    size="lg"
                    name="search"
                    type="text"
                    theme="light"
                    search
                    placeholder="Search active events..."
                    @clear="clearSearch"
                />
            </div>

            <div class="mt-0.5 p-6 pt-0">
                <app-card v-if="!hasVisibleEvents && search" class="text-sm font-semibold text-gray-600">
                    No events matching "<strong>{{ search }}</strong>" found.
                </app-card>

                <div v-if="hasEvents" class="mt-px space-y-4">
                    <infinite-scroll
                        :items="events"
                        :page="pagination.page"
                        :last-page="pagination.lastPage"
                        class="space-y-4"
                        @refetch="fetchMoreEvents"
                    >
                        <template #item="{ item: event }">
                            <div :key="event.id" class="bg-white font-semibold text-gray-700 leading-none border border-transparent rounded-lg shadow-sm">
                                <router-link :to="{ name: 'beyond-ticket-selections', params: { eventId: event.id } }">
                                    <div class="flex py-6">
                                        <div class="flex flex-col ml-6 h-full">
                                            <div class="flex-1">
                                                <h2 class="text-lg font-semibold leading-snug line-clamp-2">
                                                    {{ event.name }}
                                                </h2>

                                                <span class="inline-block mt-1.5 text-sm text-gray-500">
                                                    {{ getFormattedDate(event.begins_at) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </template>
                    </infinite-scroll>
                </div>
            </div>
        </template>
    </app-page>
</template>

<script>
import { format } from 'date-fns';
import debounce from 'lodash/debounce';

import { FormInput } from '@/components/form';
import InfiniteScroll from '@/components/utils/InfiniteScroll.vue';

import { fetchEvents } from '@/api/beyond';

export default {
    name: 'PageBeyondEvents',

    components: {
        FormInput,
        InfiniteScroll,
    },

    data() {
        return {
            hasError: false,
            isLoading: true,
            isFetching: false,
            isRefetching: false,
            isSearch: false,
            events: [],
            search: '',
            pagination: {
                page: 1,
                lastPage: 1,
                total: 0,
            },
        };
    },

    created() {
        fetchEvents(this.getUrlParams)
            .then(response => {
                console.log('PageEvents (events): The events were fetched.', response.data.data);
                this.events = response.data.data;
                this.setPaginationData(response.data.meta);
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    watch: {
        search: debounce(function () {
            this.onSearch();
        }, 300),
    },

    computed: {
        hasEvents() {
            return !!this.pagination.total;
        },

        hasVisibleEvents() {
            return !!this.events.length;
        },

        getUrlParams() {
            return { page: this.pagination.page, search: this.search };
        },
    },

    methods: {
        getFormattedDate(date) {
            return format(new Date(date), 'MMMM do, yyyy');
        },

        onSearch() {
            this.isSearch = true;
            this.pagination.page = 1;

            this.fetchEvents();
        },

        clearSearch() {
            this.search = '';
            this.isSearch = false;
            this.pagination.page = 1;

            this.fetchEvents();
        },

        fetchEvents() {
            this.isFetching = true;

            fetchEvents(this.getUrlParams)
                .then(response => {
                    console.log('PageBeyondEvents (events): The events were fetched.', response.data.data);
                    this.events = response.data.data;
                    this.setPaginationData(response.data.meta);
                })
                .catch(error => {
                    if (error.response.status >= 500) {
                        this.hasError = true;
                    }
                })
                .finally(() => {
                    this.isFetching = false;
                });
        },

        fetchMoreEvents(page) {
            this.pagination.page = page;
            if (this.pagination.page > this.pagination.lastPage) return;

            this.isRefetching = true;

            fetchEvents(this.getUrlParams)
                .then(response => {
                    console.log('PageBeyondEvents (events): More events were fetched.', response.data.data);
                    this.events.push(...response.data.data);
                    this.pagination.lastPage = response.data.meta.last_page;
                })
                .catch(error => {
                    if (error.response.status >= 500) {
                        this.hasError = true;
                    }
                })
                .finally(() => {
                    this.isRefetching = false;
                });
        },

        setPaginationData(data) {
            this.pagination.lastPage = data.last_page;

            if (this.isSearch) return;

            this.pagination.total = data.total;
        },
    },
};
</script>
