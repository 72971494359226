<template>
    <switch-toggle
        :is-loading="isCheckingIn"
        :checked="isCheckedIn"
        :disabled="disabled"
        @click="onToggleCheckIn"
    />
</template>

<script>
import SwitchToggle from '@/components/utils/SwitchToggle.vue';

import { manageCompanionCheckIns } from '@/api/beyond';

export default {
    name: 'CompanionBulkCheckInToggle',

    components: {
        SwitchToggle,
    },

    props: {
        companion: {
            type: Object,
            required: true,
        },

        tickets: {
            type: Array,
            required: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isCheckingIn: false,
            isCheckedIn: this.tickets.every(item => item.checked_in),
        };
    },

    watch: {
        companion: {
            handler() {
                this.isCheckedIn = this.tickets.every(item => item.checked_in);
            },
            deep: true,
        },
    },

    methods: {
        onToggleCheckIn() {
            this.isCheckingIn = true;

            const participantIds = [this.companion.id];
            const tickets = this.tickets.map(item => ({ ticketable_id: item.ticketable_id, ticketable_type: item.ticketable_type }));
            const checkedIn = !this.isCheckedIn;

            manageCompanionCheckIns(this.$route.params.eventId, participantIds, tickets, checkedIn)
                .then(() => {
                    const message = !checkedIn
                        ? `Checked out <strong>${this.companion.first_name} ${this.companion.last_name}</strong> from the tickets.`
                        : `Checked in <strong>${this.companion.first_name} ${this.companion.last_name}</strong> to the tickets.`;

                    this.$notify(message);
                    this.$emit('update', { checkedIn, tickets });
                })
                .catch(error => {
                    this.$emit('error', error.response.data);
                })
                .finally(() => {
                    this.isCheckingIn = false;
                });
        },
    },
};
</script>
