import router from '@/routes';
import store from '@/store';
import { isLoggedIn } from '@/core/auth';

export function authGuard(to, from, next) {
    if (!isLoggedIn()) {
        store.dispatch('auth/clearAll');
        router.replace({ name: 'login' });
    } else {
        next();
    }
}

export function loginPageGuard(to, from, next) {
    if (isLoggedIn()) {
        const eventsRoute = store.state.origin === 'beyond'
            ? { name: 'beyond-events' }
            : { name: 'legacy-events' };

        next(eventsRoute);
    } else {
        next();
    }
}
