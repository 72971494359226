<template>
    <li class="pb-5 last:pb-0 border-b last:border-b-0 border-gray-200">
        <div v-if="isFile(field.type)" class="block md:flex text-base">
            <span class="block md:inline-block mb-2 md:mb-0 md:mr-8 w-80 font-semibold text-gray-700">
                {{ field.label }}
            </span>

            <questionnaire-files-list
                v-if="Array.isArray(answer.files) && !!answer.files.length"
                :files="answer.files"
                class="ml-0.5"
            />
            <span v-else class="ml-0.5">-</span>
        </div>

        <div v-if="!isFile(field.type)" class="text-base">
            <span class="block md:inline-block mb-2 md:mb-0 md:mr-8 w-80 font-semibold text-gray-700">
                {{ field.label }}
            </span>
            <span>
                {{ answer?.value || '-' }}
            </span>
        </div>
    </li>
</template>

<script>
import QuestionnaireFilesList from './utils/QuestionnaireFilesList.vue';

export default {
    name: 'BeyondAttendeeQuestionnaireAnswer',

    components: {
        QuestionnaireFilesList,
    },

    props: {
        field: {
            type: Object,
            default: () => {},
        },

        answer: {
            type: Object,
            default: () => {},
        },
    },

    methods: {
        isFile(type) {
            return type === 'file';
        },
    },
};
</script>
