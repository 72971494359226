<template>
    <div
        class="inline-flex items-center text-2xs font-bold text-white text-center leading-4 rounded-full"
        :class="[bgClass, sizeClass]"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'AppBadge',

    props: {
        type: {
            type: String,
            default: 'default',
        },

        size: {
            type: String,
            default: 'md',
        },
    },

    computed: {
        bgClass() {
            const backgrounds = {
                primary: 'bg-brand-500',
                default: 'bg-gray-700',
                danger: 'bg-red-700',
                warning: 'bg-yellow-600',
                success: 'bg-green-600',
            };

            return backgrounds[this.type];
        },

        sizeClass() {
            const sizes = {
                sm: 'py-1 px-[0.625rem]',
                md: 'py-1.5 px-3',
            };

            return sizes[this.size];
        },
    },
};
</script>
