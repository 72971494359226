var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{ref:"label",class:[
        [_vm.group ? 'flex' : 'inline-flex'],
        [_vm.group && !_vm.error ? 'items-center' : 'items-start'],
        { 'items-start': _vm.group && _vm.error },
        { 'flex-col': _vm.error },
        { 'cursor-not-allowed': _vm.disabled },
    ],attrs:{"for":_vm.id},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();return _vm.$refs.label.click()}}},[_c('div',{staticClass:"flex items-start"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newValue),expression:"newValue"}],ref:"checkbox",staticClass:"form-checkbox relative",class:[_vm.group ? 'top-0.5' : '-top-px'],attrs:{"true-value":_vm.trueValue,"false-value":_vm.falseValue,"name":_vm.name,"required":_vm.required,"disabled":_vm.disabled,"tabindex":_vm.disabled ? false : 0,"type":"checkbox"},domProps:{"value":_vm.nativeValue,"checked":Array.isArray(_vm.newValue)?_vm._i(_vm.newValue,_vm.nativeValue)>-1:_vm._q(_vm.newValue,_vm.trueValue)},on:{"change":[function($event){var $$a=_vm.newValue,$$el=$event.target,$$c=$$el.checked?(_vm.trueValue):(_vm.falseValue);if(Array.isArray($$a)){var $$v=_vm.nativeValue,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.newValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.newValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.newValue=$$c}},function($event){return _vm.$emit('change')}],"click":function($event){return _vm.$emit('click')}}}),_c('div',{staticClass:"text-xs font-semibold text-gray-700",class:[!_vm.group ? 'ml-2.5' : 'ml-4']},[_vm._t("default"),(_vm.error)?_c('form-util-error',{attrs:{"error":_vm.error}}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }