export default {
    duration: 7500,
    reverse: false,
    type: {
        flash: 'flash',
        notification: 'notification',
        announcement: 'announcement',
    },
    itemStatus: {
        success: 'success',
        error: 'error',
        warning: 'warning',
    },
    itemState: {
        active: 'active',
        destroyed: 'destroyed',
    },
};
