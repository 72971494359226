<template>
    <app-page
        title="Check in attendees"
        paddingless
        :loading="isLoading"
        :error="hasError"
        class="relative"
    >
        <template v-if="!hasError">
            <div class="sticky top-0 z-10 mx-2 pt-6 pb-4 px-4 bg-gray-200">
                <form-input
                    ref="search"
                    v-model="search"
                    size="lg"
                    name="search"
                    type="text"
                    theme="light"
                    search
                    placeholder="Search attendees..."
                    @clear="clearSearch"
                />
            </div>

            <div class="p-6 pt-0">
                <app-card v-if="!hasAttendees" class="text-sm font-semibold text-gray-600">
                    No registered attendees were found.
                </app-card>

                <app-card v-if="!hasVisibleAttendees && search" class="text-sm font-semibold text-gray-600">
                    No attendees matching "<strong>{{ search }}</strong>" found.
                </app-card>

                <infinite-scroll
                    :items="attendees"
                    :page="pagination.page"
                    :last-page="pagination.lastPage"
                    class="space-y-4"
                    @refetch="fetchMoreAttendees"
                >
                    <template #item="{ item: attendee }">
                        <app-card
                            class="cursor-pointer"
                            @click.native="goToAttendeeProfile(attendee)"
                            @keydown.native="goToAttendeeProfile(attendee)"
                        >
                            <div class="flex justify-between items-center">
                                <div>
                                    <h2 class="text-lg font-semibold line-clamp-2">
                                        {{ attendee.full_name }}
                                    </h2>

                                    <div class="flex items-center">
                                        <template v-if="isEventSelected">
                                            <div class="flex-shrink-0 w-5 text-center">
                                                <svg
                                                    v-if="attendee.checked_in_info.is_checked_into_event"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    zoomAndPan="magnify"
                                                    viewBox="0 0 30 30.000001"
                                                    preserveAspectRatio="xMidYMid meet"
                                                    version="1.0"
                                                    class="h-4 w-4 text-green-600"
                                                >
                                                    <defs>
                                                        <clipPath id="id1">
                                                            <path
                                                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                                                clip-rule="nonzero"
                                                                fill="#2eaf2c"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                    <g clip-path="url(#id1)">
                                                        <path
                                                            fill="green"
                                                            d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                                                            fill-opacity="1"
                                                            fill-rule="nonzero"
                                                        />
                                                    </g>
                                                </svg>

                                                <svg
                                                    v-if="!attendee.checked_in_info.is_checked_into_event"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="h-4 w-4 text-red-500"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    stroke-width="3"
                                                >
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
                                                </svg>
                                            </div>

                                            <span class="ml-1.5 mr-4 text-xs text-gray-600 font-semibold whitespace-nowrap">Event</span>
                                        </template>

                                        <span class="ml-0.5 text-xs text-gray-600 font-semibold whitespace-nowrap">
                                            {{ attendee.checked_in_info.checked_in_tickets_count }} / {{ attendee.checked_in_info.purchased_ticket_count }} tickets
                                        </span>
                                    </div>
                                </div>

                                <div class="ml-4 -mr-2.5">
                                    <app-checkbox-toggle
                                        :checked="isCheckedIn(attendee)"
                                        @click.native.stop="onCheckIn(attendee)"
                                    />
                                </div>
                            </div>
                        </app-card>
                    </template>
                </infinite-scroll>

                <app-loader v-if="isRefetching" class="mt-6 mb-2" />
            </div>
        </template>
    </app-page>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';

import { FormInput } from '@/components/form';
import InfiniteScroll from '@/components/utils/InfiniteScroll.vue';

import {
    fetchAttendees,
    checkInAttendees,
} from '@/api/beyond';

export default {
    name: 'PageBeyondCheckInAttendees',

    components: {
        FormInput,
        InfiniteScroll,
    },

    props: {
        eventId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            hasError: false,
            isLoading: true,
            isFetching: false,
            isRefetching: false,
            isSubmitting: false,
            isSearch: false,
            attendees: [],
            search: '',
            pagination: {
                page: 1,
                lastPage: 1,
                total: 0,
            },
        };
    },

    created() {
        fetchAttendees(this.eventId, this.getUrlParams)
            .then(response => {
                console.log('PageCheckInAttendees (attendees): The attendees were fetched.', response.data.data);
                this.attendees = response.data.data;
                this.setPaginationData(response.data.meta);
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    watch: {
        search: debounce(function () {
            this.onSearch();
        }, 300),
    },

    computed: {
        ...mapGetters('beyond', ['selectedTickets']),

        hasAttendees() {
            return !!this.pagination.total;
        },

        hasVisibleAttendees() {
            return !!this.attendees.length;
        },

        isEventSelected() {
            return this.selectedTickets.some(ticket => ticket.type === 'App\\Project');
        },

        tickets() {
            return this.selectedTickets.map(item => ({ ticketable_id: item.id, ticketable_type: item.type }));
        },

        getUrlParams() {
            return { page: this.pagination.page, search: this.search, tickets: this.tickets };
        },
    },

    methods: {
        fetchAttendees() {
            this.isFetching = true;

            fetchAttendees(this.eventId, this.getUrlParams)
                .then(response => {
                    console.log('PageCheckInAttendees (attendees): The attendees were fetched.', response.data.data);
                    this.attendees = response.data.data;
                    this.setPaginationData(response.data.meta);
                })
                .catch(error => {
                    if (error.response.status >= 500) {
                        this.hasError = true;
                    }
                })
                .finally(() => {
                    this.isFetching = false;
                });
        },

        fetchMoreAttendees(page) {
            this.pagination.page = page;
            if (this.pagination.page > this.pagination.lastPage) return;

            this.isRefetching = true;

            fetchAttendees(this.eventId, this.getUrlParams)
                .then(response => {
                    console.log('PageCheckInAttendees (attendees): More attendees were fetched.', response.data.data);
                    this.attendees.push(...response.data.data);
                    this.pagination.lastPage = response.data.meta.last_page;
                })
                .catch(error => {
                    if (error.response.status >= 500) {
                        this.hasError = true;
                    }
                })
                .finally(() => {
                    this.isRefetching = false;
                });
        },

        onSearch() {
            this.isSearch = true;
            this.pagination.page = 1;

            this.fetchAttendees();
        },

        clearSearch() {
            this.search = '';
            this.isSearch = false;
            this.pagination.page = 1;

            this.fetchAttendees();
        },

        setPaginationData(data) {
            this.pagination.lastPage = data.last_page;

            if (this.isSearch) return;

            this.pagination.total = data.total;
        },

        goToAttendeeProfile(attendee) {
            this.$router.push({ name: 'beyond-attendee-profile', params: { eventId: this.eventId, attendeeId: attendee.id } });
        },

        isCheckedIn(attendee) {
            return attendee.checked_in_info.checked_in_tickets_count === attendee.checked_in_info.purchased_ticket_count;
        },

        hasPreviousCheckIn(attendee) {
            return !!attendee.checked_in_info.checked_in_tickets_count;
        },

        updateAttendeeTicketData(attendee) {
            if (this.isEventSelected) {
                attendee.checked_in_info.is_checked_into_event = !attendee.checked_in_info.is_checked_into_event;
            }
            attendee.checked_in_info.checked_in_tickets_count = attendee.checked_in_info.purchased_ticket_count;
        },

        onCheckIn(attendee) {
            this.isSubmitting = true;

            if (this.hasPreviousCheckIn(attendee)) {
                this.$router.push({ name: 'beyond-check-in-options', params: { eventId: this.eventId, attendeeId: attendee.id } });
                return;
            }

            checkInAttendees(this.eventId, attendee.id, { tickets: this.tickets })
                .then(() => {
                    this.$notify(`Checked in <strong>${attendee.full_name}</strong> successfully.`);
                    this.updateAttendeeTicketData(attendee);
                })
                .catch(() => {
                    this.$notify({
                        status: 'error',
                        message: 'It wasn\'t possible to check in the attendee.<br> Try again later.',
                    });
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        },
    },
};
</script>
