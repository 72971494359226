<template>
    <app-page
        title="Tickets"
        paddingless
        :loading="isLoading"
        :error="hasError"
        class="relative"
    >
        <app-no-items
            v-if="!hasTickets && !hasError"
            message="There are no ticket options available in the selected events."
        />

        <template v-if="!hasError">
            <div class="sticky top-0 z-10 mx-2 pt-6 pb-4 px-4 bg-gray-200">
                <form-input
                    ref="search"
                    v-model="search"
                    size="lg"
                    name="search"
                    type="text"
                    theme="light"
                    search
                    placeholder="Search tickets..."
                    @clear="search = ''"
                />
            </div>

            <div class="p-6 pt-0">
                <app-message
                    v-if="hasFilteredTickets"
                    type="default"
                    class="mb-4 font-semibold !text-gray-700"
                >
                    <div class="flex items-center">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512">
                                <path fill="#68696b" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                            </svg>
                        </span>

                        <span class="ml-3">
                            The participants will only be checked in to the tickets purchased by them.
                        </span>
                    </div>
                </app-message>

                <app-card v-if="!hasFilteredTickets && search" class="text-sm font-semibold text-gray-600">
                    No ticket options matching "<strong>{{ search }}</strong>" found.
                </app-card>

                <div v-if="!hasFilteredTickets && !search" class="text-sm text-gray-600">
                    There are no ticket options available in this event.
                </div>

                <div v-if="hasFilteredTickets" class="mt-0.5 space-y-4">
                    <div v-for="ticket in filteredTickets" :key="ticket.id">
                        <div class="grid grid-cols-1 gap-2">
                            <form-checkbox-button
                                :native-value="ticket"
                                show-check
                                :value="selectedTickets"
                                @input="updateSelectedTickets"
                            >
                                <div class="flex mr-4 py-4">
                                    <div class="flex flex-col ml-2.5 h-full">
                                        <div class="flex-1">
                                            <div class="text-lg font-semibold line-clamp-2">
                                                {{ ticket.title }}
                                            </div>
                                            <div class="mt-1 text-sm font-semibold text-gray-500 line-clamp-1">
                                                {{ getTicketType(ticket.type) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form-checkbox-button>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <div v-if="hasTickets" class="space-y-3">
                <app-button
                    appearance="primary"
                    size="lg"
                    class="w-full"
                    :disabled="!hasSelectedTickets"
                    @click="$router.push({ name: 'beyond-scanner', params: { eventId: $route.params.eventId } })"
                >
                    {{ checkInLabel }}
                </app-button>
            </div>
        </template>
    </app-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { FormInput, FormCheckboxButton } from '@/components/form';

import { fetchTickets } from '@/api/beyond';

export default {
    name: 'PageBeyondTicketSelections',

    components: {
        FormInput,
        FormCheckboxButton,
    },

    data() {
        return {
            isLoading: true,
            hasError: false,
            tickets: [],
            search: '',
        };
    },

    created() {
        fetchTickets(this.$route.params.eventId)
            .then(response => {
                console.log('PageBeyondTicketSelections (tickets): The tickets were fetched.', response.data.data);
                this.tickets = response.data.data;
            })
            .catch(error => {
                if (error.response.status >= 500) {
                    this.hasError = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    computed: {
        ...mapState('beyond', ['selectedTickets']),

        hasTickets() {
            return !!this.tickets.length;
        },

        hasFilteredTickets() {
            return !!this.filteredTickets.length;
        },

        hasSelectedTickets() {
            return !!this.selectedTickets.length;
        },

        checkInLabel() {
            return !this.hasSelectedTickets
                ? 'Please select at least one ticket'
                : 'To check in';
        },

        filteredTickets() {
            if (!this.search) return this.tickets;

            const regex = new RegExp(`(${this.search})`, 'i');

            return [...this.tickets].filter(ticket => ticket.title.match(regex));
        },
    },

    methods: {
        ...mapActions('beyond', ['updateSelectedTickets']),

        getTicketType(type) {
            const types = {
                'App\\Project': 'Event',
                'App\\EntranceTicketType': 'Entrance ticket',
                'App\\ActivityTicket': 'Activity',
            };

            return types[type];
        },
    },
};
</script>
