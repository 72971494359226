<template>
    <app-page
        title="Manage companion check-ins"
        paddingless
        actionless
        :loading="isLoading"
        :error="hasError"
        class="relative"
    >
        <div v-if="!hasError">
            <div class="sticky top-0 z-10 mx-2 pt-6 pb-4 px-4 bg-gray-200">
                <form-input
                    ref="search"
                    v-model="search"
                    size="lg"
                    name="search"
                    type="text"
                    theme="light"
                    search
                    placeholder="Search companions by name..."
                    @clear="search = ''"
                />
            </div>

            <div class="p-6 pt-0">
                <app-card v-if="!hasParticipants && search" class="text-sm font-semibold text-gray-600">
                    No participant matching the search term "<strong>{{ search }}</strong>" found.
                </app-card>

                <div v-if="!hasParticipants && !search" class="text-sm text-gray-600">
                    There are no participants in this group booking.
                </div>

                <div v-if="hasParticipants" class="space-y-4">
                    <app-card>
                        <div class="flex justify-end font-semibold text-gray-700">
                            <div class="text-sm mr-3">Check in all companions</div>
                            <div>
                                <beyond-companions-toggle
                                    :participants="participants"
                                    :tickets="tickets"
                                    :checked-in="isCheckedIn"
                                    @update="updateStatus($event)"
                                    @error="onError"
                                />
                            </div>
                        </div>
                    </app-card>

                    <app-card v-for="participant in filteredParticipants" :key="participant.id">
                        <div class="flex items-center">
                            <beyond-companion :participant="participant" />
                        </div>
                    </app-card>
                </div>
            </div>
        </div>
    </app-page>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import { FormInput } from '@/components/form';

import BeyondCompanion from './components/BeyondCompanion.vue';
import BeyondCompanionsToggle from './components/BeyondCompanionsToggle.vue';

import { fetchCompanions } from '@/api/beyond';

export default {
    name: 'PageBeyondCheckInCompanions',

    components: {
        FormInput,
        BeyondCompanion,
        BeyondCompanionsToggle,
    },

    props: {
        eventId: {
            type: String,
            required: true,
        },

        attendeeId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isLoading: true,
            hasError: false,
            participants: [],
            search: '',
            errors: {},
        };
    },

    created() {
        fetchCompanions(this.eventId, this.attendeeId, this.tickets)
            .then(response => {
                console.log('PageBeyondCheckInCompanions (participants): The participants in the group booking were fetched.', response.data.data);
                this.participants = response.data.data;
            })
            .catch(error => {
                console.error(error);
                this.hasError = true;
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    computed: {
        ...mapGetters('beyond', ['selectedTickets']),

        hasParticipants() {
            return !!this.filteredParticipants.length;
        },

        filteredParticipants() {
            if (!this.search) return this.participants;

            const regex = new RegExp(`(${this.search})`, 'i');

            return [...this.participants].filter(participant => `${participant.first_name} ${participant.last_name}`.match(regex));
        },

        tickets() {
            return this.selectedTickets.map(item => ({ ticketable_id: item.id, ticketable_type: item.type }));
        },

        isCheckedIn() {
            const allTicketsCheckedIn = this.participants.every(participant => participant.tickets.every(ticket => ticket.checked_in));
            const allProjectsCheckedIn = this.participants.every(participant => participant.projects.every(project => project.checked_in));

            return allTicketsCheckedIn && allProjectsCheckedIn;
        },
    },

    methods: {
        getFormattedDate(date) {
            return format(new Date(date), 'yyyy-MM-dd HH:mm');
        },

        getCheckedInTimestamp(item) {
            let timestamp = item.checked_in_at;

            if (!timestamp) {
                timestamp = this.getFormattedDate(new Date().toISOString());
            }

            return timestamp;
        },

        updateStatus(checkedIn) {
            this.participants = this.participants.map(participant => {
                participant.tickets = participant.tickets.map(ticket => {
                    ticket.checked_in = checkedIn;
                    ticket.checked_in_at = checkedIn ? this.getCheckedInTimestamp(ticket) : null;
                    return ticket;
                });
                participant.projects = participant.projects.map(project => {
                    project.checked_in = checkedIn;
                    project.checked_in_at = checkedIn ? this.getCheckedInTimestamp(project) : null;
                    return project;
                });
                return participant;
            });
        },

        onError(error) {
            this.errors = error.errors;
        },
    },
};
</script>
