import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import axios from 'axios';
import VueObserveVisibility from 'vue-observe-visibility';
import AppNotifications from '@/components/global/notifications';
import router from '@/routes';
import store from '@/store';

import '@/assets/css/tailwind.css';
import '@/assets/css/style.css';

import { getAccessToken } from '@/core/auth';

window.axios = axios;
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['localhost', process.env.VUE_APP_SENTRY_DOMAIN, /^\//],
        }),
    ],
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE,
    logErrors: true,
});

Vue.use(VueObserveVisibility);
Vue.use(AppNotifications);

const accessToken = getAccessToken();

if (accessToken) {
    window.axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

window.axios.interceptors.response.use(response => response, error => {
    if (error.response.status === 401) {
        if (router.history.current.name === 'login' || router.history.current.path.includes('beyond')) {
            store.dispatch('auth/clearAll');
        }
    }

    return Promise.reject(error);
});
